import * as React from "react";
export const OrderPlacedIcon = ({props, active = '#309740'}) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={15}
      fill="white"
      stroke={active}
      strokeWidth={2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66699 11.4232C9.66699 10.9629 10.0401 10.5898 10.5003 10.5898H11.7322C12.1666 10.5898 12.5281 10.9235 12.5629 11.3565L12.6435 12.3607H21.5873C21.825 12.3607 22.0514 12.4622 22.2095 12.6397C22.3677 12.8172 22.4424 13.0538 22.4151 13.29L21.9587 17.2263C21.82 18.4228 20.8478 19.3503 19.6461 19.4325L14.0484 19.8157C12.6824 19.9092 11.4953 18.8864 11.3857 17.5215L11.0437 13.2607L10.9631 12.2565H10.5003C10.0401 12.2565 9.66699 11.8834 9.66699 11.4232ZM12.7773 14.0273L13.047 17.3882C13.0835 17.8432 13.4792 18.1841 13.9346 18.1529L19.5322 17.7697C19.9328 17.7423 20.2569 17.4332 20.3031 17.0343L20.6517 14.0273H12.7773ZM13.0547 21.753C13.0547 21.2928 13.4278 20.9197 13.888 20.9197H13.8945C14.3547 20.9197 14.7278 21.2928 14.7278 21.753V21.7592C14.7278 22.2195 14.3547 22.5926 13.8945 22.5926H13.888C13.4278 22.5926 13.0547 22.2195 13.0547 21.7592V21.753ZM19.4315 20.9197C18.9712 20.9197 18.5982 21.2928 18.5982 21.753V21.7592C18.5982 22.2195 18.9712 22.5926 19.4315 22.5926H19.438C19.8982 22.5926 20.2713 22.2195 20.2713 21.7592V21.753C20.2713 21.2928 19.8982 20.9197 19.438 20.9197H19.4315Z"
      fill={active}
    />
  </svg>
);
