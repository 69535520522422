import { CNPJ, CPF } from 'cpf_cnpj'
import { Checkbox, Form, FormError, Radio, RadioGroup, Text } from 'react-form'
import {
  InputCheckbox,
  InputText,
  SelectState,
  regexEmail,
  regexPassword,
  regexZipcode,
  InputPassword,
} from '@shared/CustomField'
import React, { Component } from 'react'
import NormalizeBrazilianStates from '@shared/NormalizeBrazilianStates'

import { getFreight, resetFreight } from '@actions/freight'

import Cep from 'cep-promise'
import ConfirmDialog from '@shared/ConfirmDialog'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import { closeLoader, openLoader } from '@actions/loader.js'
import { closeModal, openModal } from '@actions/modal.js'
import { listVariables } from '@services/sm-api'
import { withRouter } from '@utils/withRouter'
import moment from 'moment'

class RegisterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingField: 'cpf',
      fullAddress: {},
      email: '',
      userAlreadyExists: {},
      address_zipcode: '',
      address_found: false,
      address_incomplete: false,
      delivery_address_zipcode: '',
      delivery_address_found: false,
      delivery_address_incomplete: false,
      ie_free: false,
      ieModalOpen: false,
      school_is_b2c: false,
      variableTermUse: {},
      variablePrivacyPolicy: {},
      addressModalClose: false,
      unique_zipcode: false,
      invalid_address_zipcode: false,
      disable_address_state: true,
      disable_delivery_address_state: true,
      delivery_invalid_address_zipcode: false,
      same_address: true,
      showModalFreight: false,
      showConfirmEmailModal: false,
      delivery_unique_zipcode: false,
      dataToRegister: null,
    }

    this.hideDefaultError = { style: { display: 'none' } }
    this._getAddress = this._getAddress.bind(this)
    this._getDeliveryAddress = this._getDeliveryAddress.bind(this)
    this._validateForm = this._validateForm.bind(this)
  }

  /**
   * Set error on email field if the email already exists
   **/
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof nextProps.user.error !== 'undefined' && this.refs.Form.state.errors) {
      this.refs.Form.state.errors.email = nextProps.user.error
    }
  }

  /**
   * Get full address by CEP number and fill the adresses fields
   **/
  _getAddress(event, setValue, setAllValues) {
    this.props.openLoader()
    const value = event.target.value
    let cep = value.replace('-', '')

    if (cep.length === 8) {
      Cep(cep)
        .then(data => {
          this.setState({
            address_found: true,
            address_incomplete: false,
          })
          setValue('address_number', '', true)
          setValue('address_complement', '', true)
          setValue('address_reference', '', true)
          setValue('address_street', data.street, true)
          setValue('address_city', data.city, true)
          setValue('address_neighborhood', data.neighborhood, true)
          if (data.state && NormalizeBrazilianStates(data.state)) {
            setValue('address_state', NormalizeBrazilianStates(data.state), true)
          } else {
            this.setState({ disable_address_state: false })
          }

          if (this.state.same_address) {
            this._getFreight(data)
            setValue('address_zipcode_delivery', value, true)
            setValue('address_number_delivery', '', true)
            setValue('address_complement_delivery', '', true)
            setValue('address_reference_delivery', '', true)
            setValue('address_street_delivery', data.street, true)
            setValue('address_city_delivery', data.city, true)
            setValue('address_neighborhood_delivery', data.neighborhood, true)
            if (data.state && NormalizeBrazilianStates(data.state)) {
              setValue('address_state_delivery', NormalizeBrazilianStates(data.state), true)
            } else {
              this.setState({ disable_delivery_address_state: false })
            }
          }
          this.setState({
            invalid_address_zipcode: false,
          })
          if (this._isUniqueZipcode(data)) {
            this.setState({
              unique_zipcode: true,
            })
          } else {
            this.setState({
              unique_zipcode: false,
            })
          }
        })
        .catch(e => {
          console.log(e)
          if (this.state.same_address) {
            this._getFreight()
          }
          ;[
            'address_number',
            'address_complement',
            'address_reference',
            'address_street',
            'address_city',
            'address_neighborhood',
            'address_state',
          ].forEach(field => {
            setValue(field, '', true)
          })

          this.props.openModal(
            <ConfirmDialog
              text="Dados não encontrados para o cep informado."
              okLabel="Confirmar"
              ok={() => {
                this.props.closeModal()
              }}
            />,
          )

          this.setState({
            address_found: false,
            address_incomplete: true,
            unique_zipcode: false,
            invalid_address_zipcode: true,
          })
        })
    }
    this.props.closeLoader()
  }

  /**
   * Get full address by CEP number and fill the adresses fields
   **/
  _getDeliveryAddress(event, setValue, setAllValues) {
    this.props.openLoader()
    const value = event.target.value
    let cep = value.replace('-', '')

    if (cep.length === 8) {
      Cep(cep)
        .then(data => {
          this._getFreight(data)
          this.setState({
            delivery_address_found: true,
            delivery_address_incomplete: false,
          })
          setValue('address_number_delivery', '', true)
          setValue('address_complement_delivery', '', true)
          setValue('address_reference_delivery', '', true)
          setValue('address_street_delivery', data.street, true)
          setValue('address_city_delivery', data.city, true)
          setValue('address_neighborhood_delivery', data.neighborhood, true)
          if (data.state && NormalizeBrazilianStates(data.state)) {
            setValue('address_state_delivery', NormalizeBrazilianStates(data.state), true)
          } else {
            this.setState({ disable_delivery_address_state: false })
          }
          this.setState({
            delivery_invalid_address_zipcode: false,
          })
          if (this._isUniqueZipcode(data)) {
            this.setState({
              delivery_unique_zipcode: true,
            })
          } else {
            this.setState({
              delivery_unique_zipcode: false,
            })
          }
        })
        .catch(e => {
          console.log(e)
          this._getFreight()
            ;[
              'address_number_delivery',
              'address_complement_delivery',
              'address_reference_delivery',
              'address_street_delivery',
              'address_city_delivery',
              'address_neighborhood_delivery',
              'address_state_delivery',
            ].forEach(field => {
              setValue(field, '', true)
            })

          this.props.openModal(
            <ConfirmDialog
              text="Dados não encontrados para o cep informado."
              okLabel="Confirmar"
              ok={() => {
                this.props.closeModal()
              }}
            />,
          )

          this.setState({
            delivery_address_found: false,
            delivery_address_incomplete: true,
            delivery_unique_zipcode: false,
            delivery_invalid_address_zipcode: true,
          })
        })
    }
    this.props.closeLoader()
  }

  _isUniqueZipcode(address) {
    if (address.street === 'undefined' || address.street.length === 0) {
      return true
    }
    if (address.neighborhood === 'undefined' || address.neighborhood.length === 0) {
      return true
    }
    return false
  }

  /**
   * Format fields and send data to action
   **/
  _handleRegister(values) {
    delete values['same_address_']
    delete values['confirm_password']
    delete values['confirm_email']

    // Rule in case the user does not click the checkbox.
    if (values.receive_offers === undefined) {
      values.receive_offers = 1
    }
    /*if (values.receive_surveys === undefined) {
      values.receive_surveys = 1
    }*/
    if (this.state.school_is_b2c) {
      values.b2c_terms_of_use_accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      values.b2c_privacy_policy_accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      delete values.terms_of_use_accepted_at
      delete values.privacy_policy_accepted_at
    } else {
      values.terms_of_use_accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      values.privacy_policy_accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      delete values.b2c_terms_of_use_accepted_at
      delete values.b2c_privacy_policy_accepted_at
    }

    if (values['ie_free'] === 1) {
      values['ie'] = 'ISENTO'
    }

    values.role_id = 1

    this.setState({
      showConfirmEmailModal: true,
      dataToRegister: values,
    })
  }

  _handleSubmit() {
    this.setState({
      showConfirmEmailModal: false,
    })
    this.props
      .submitRegisterForm(this.state.dataToRegister, true)
      .then(response => {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'compra',
          eventAction: 'click',
          eventLabel: 'cadastrou_4.1',
        })

        this.props.requestLogin(this.state.dataToRegister)
        this.props.navigate('/steps/pagamento')
      })
      .catch(error => {
        this.setState({
          errorModalOpen: true,
        })
      })
  }

  /**
   * Verify if the email is already taken
   **/
  _getUserByEmail(event) {
    const email = event.target.value
    return this.props.getUserByEmail({ email }).then(() => {
      return new Promise(resolve => {
        this.setState(
          {
            email,
          },
          () => setTimeout(() => resolve(email), 300),
        )
      })
    })
  }

  /**
   * Toggle CPF and CNPJ fields
   **/
  _toggleTypeField(name, setValue) {
    if (name === 'cnpj') this.setState({ ieModalOpen: true })
    this.setState({
      showingField: name,
    })
    setValue('cpf', '', true)
    setValue('cnpj', '', true)
  }

  /**
   * Toggle CPF and CNPJ fields
   **/
  _toggleSameAddress(event, setValue, getValue) {
    // const same_address__ = event.target.value === 0 ? false : true
    const same_address__ = !this.state.same_address

    if (same_address__) {
      if (getValue('address_zipcode')) {
        this._getFreight({
          cep: getValue('address_zipcode'),
          city: getValue('address_city'),
          neighborhood: getValue('address_neighborhood'),
          state: getValue('address_state'),
          street: getValue('address_street'),
        })
      } else {
        this._getFreight()
      }
      setValue('address_zipcode_delivery', getValue('address_zipcode'), true)
      setValue('address_number_delivery', getValue('address_number'), true)
      setValue('address_complement_delivery', getValue('address_complement'), true)
      setValue('address_reference_delivery', getValue('address_reference'), true)
      setValue('address_street_delivery', getValue('address_street'), true)
      setValue('address_city_delivery', getValue('address_city'), true)
      setValue('address_neighborhood_delivery', getValue('address_neighborhood'), true)
      setValue('address_state_delivery', getValue('address_state'), true)
    } else {
      ;[
        'address_zipcode_delivery',
        'address_number_delivery',
        'address_complement_delivery',
        'address_reference_delivery',
        'address_street_delivery',
        'address_city_delivery',
        'address_neighborhood_delivery',
        'address_state_delivery',
      ].forEach(field => {
        setValue(field, '', true)
      })
      this._getFreight()
    }
    this.setState({
      same_address: same_address__,
      delivery_address_found: false,
      delivery_address_incomplete: true,
      delivery_unique_zipcode: false,
      delivery_invalid_address_zipcode: true,
    })
  }

  /**
   * All validations rules
   **/
  _validateForm(values) {
    const errorMsg = {
      empty: 'Campo obrigatório',
      validZipcode: 'Cep Inválido',
      notfoundZipcode: 'Cep Inválido',
      validEmail: 'Email inválido',
      password: `A senha deve conter no mínimo 8 caracteres, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula, números de 0 a 9 e pelo menos um caractere especial (por exemplo '~ ! @ # $ % ^ & * ( ) _ + - = { } | \\ : " ; ' < > ? , . /)`,
      passwordLength: `A senha deve conter no mínimo 8 caracteres, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula, números de 0 a 9 e pelo menos um caractere especial (por exemplo '~ ! @ # $ % ^ & * ( ) _ + - = { } | \\ : " ; ' < > ? , . /)`,
      confirmEmail: 'Os e-mails devem ser iguais',
      confirmPassword: 'As senhas devem ser iguais',
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
      ie: 'Inscrição Estadual inválida',
      addressNumberLength: 'O número deve conter no máximo 10 caracteres',
      addressComplementLength: 'O complemento deve conter no máximo 10 caracteres',
      addressReferenceLength: 'O Ponto de referência deve conter no máximo 40 caracteres',
      phone: 'Telefone inválido. Ex (99) 9999-99999',
    }
    const {
      email,
      confirm_email,
      password,
      confirm_password,
      full_name,
      phone,
      cpf,
      cnpj,
      ie,
      address_zipcode,
      address_street,
      address_number,
      address_neighborhood,
      address_city,
      address_state,
      address_complement,
      address_reference,
      address_zipcode_delivery,
      address_street_delivery,
      address_number_delivery,
      address_neighborhood_delivery,
      address_city_delivery,
      address_state_delivery,
      address_complement_delivery,
      address_reference_delivery,
      type,
      terms_of_use_accepted_at,
      privacy_policy_accepted_at,
    } = values



    if (address_street) {
      var errorAddressStreet = undefined
    }
    if (address_street && address_street.length < 3) {
      var errorAddressStreet = 'Endereço inválido, mínimo de 3 caracteres'
    }
    if (!address_street) {
      var errorAddressStreet = errorMsg.empty
    }

    if (address_street_delivery) {
      var errorAddressStreetDelivery = undefined
    }
    if (address_street_delivery && address_street_delivery.length < 3) {
      var errorAddressStreetDelivery = 'Endereço inválido, mínimo de 3 caracteres'
    }
    if (!address_street_delivery) {
      var errorAddressStreetDelivery = errorMsg.empty
    }




    let error = {
      email: !email ? errorMsg.empty : !regexEmail.test(email) ? errorMsg.validEmail : undefined,

      confirm_email: confirm_email !== email ? errorMsg.confirmEmail : undefined,

      password: !password ? errorMsg.empty : !regexPassword.test(password) ? errorMsg.passwordLength : undefined,

      confirm_password: confirm_password !== password ? errorMsg.confirmPassword : undefined,

      full_name: !full_name ? errorMsg.empty : undefined,
      phone: !phone ? errorMsg.empty : phone.length < 10 ? errorMsg.phone : undefined,
      address_zipcode: !address_zipcode
        ? errorMsg.empty
        : !regexZipcode.test(address_zipcode)
          ? errorMsg.validZipcode
          : undefined,

      address_street: errorAddressStreet,
      address_number: !address_number
        ? errorMsg.empty
        : address_number.length > 10
          ? errorMsg.addressNumberLength
          : undefined,
      address_neighborhood: !address_neighborhood ? errorMsg.empty : undefined,
      address_city: !address_city ? errorMsg.empty : undefined,
      address_state: !address_state ? errorMsg.empty : undefined,
      address_complement:
        address_complement && address_complement.length > 10 ? errorMsg.addressComplementLength : undefined,
      address_reference: address_reference && address_reference.length > 40 ? errorMsg.addressReferenceLength : undefined,
      type: !type ? errorMsg.empty : undefined,
      terms_of_use_accepted_at: !terms_of_use_accepted_at ? errorMsg.empty : undefined,
      privacy_policy_accepted_at: !privacy_policy_accepted_at ? errorMsg.empty : undefined,
    }

    if (this.state.school_is_b2c) {
      error = {
        ...error,
        address_zipcode_delivery: !address_zipcode_delivery
          ? errorMsg.empty
          : !regexZipcode.test(address_zipcode_delivery)
            ? errorMsg.validZipcode
            : undefined,

        address_street_delivery: errorAddressStreetDelivery,
        address_number_delivery: !address_number_delivery
          ? errorMsg.empty
          : address_number_delivery.length > 10
            ? errorMsg.addressNumberLength
            : undefined,
        address_neighborhood_delivery: !address_neighborhood_delivery ? errorMsg.empty : undefined,
        address_city_delivery: !address_city_delivery ? errorMsg.empty : undefined,
        address_state_delivery: !address_state_delivery ? errorMsg.empty : undefined,
        address_complement_delivery:
          address_complement_delivery && address_complement_delivery.length > 10
            ? errorMsg.addressComplementLength
            : undefined,
        address_reference_delivery: address_reference_delivery && address_reference_delivery.length > 40
        ? errorMsg.addressReferenceLength
        : undefined,
      }
    }

    if (this.state.showingField === 'cpf') {
      error.cpf = !CPF.isValid(cpf) ? 'CPF inválido' : !cpf ? errorMsg.empty : undefined
    }

    if (!address_zipcode || !regexZipcode.test(address_zipcode)) {
      this.setState({
        address_incomplete: true,
      })
    }

    if (!address_zipcode_delivery || !regexZipcode.test(address_zipcode_delivery)) {
      this.setState({
        delivery_address_incomplete: true,
      })
    }

    if (this.state.showingField === 'cnpj') {
      error.cnpj = !CNPJ.isValid(cnpj) ? 'CNPJ inválido' : !cnpj ? errorMsg.empty : undefined
      error.ie = !ie ? 'Inscrição Estadual obrigatória' : undefined
    }

    if (this.props.user.error) {
      error.email = this.props.user.error
    }

    return error
  }

  _toggleIE() {
    this.setState({ ie_free: !this.state.ie_free })
    if (this.state.ie_free) {
      this.refs.Form.setValue('ie', '')
    } else {
      this.refs.Form.setValue('ie', 'ISENTO')
    }
  }

  UNSAFE_componentWillMount() {
    let { specialDiscount } = this.props

    const has_special_discount = !_.isEmpty(specialDiscount) && !_.isEmpty(specialDiscount.special_discounts_document)

    if (has_special_discount) {
      setTimeout(() => {
        this.refs.Form.setValue('type', specialDiscount.special_discounts_doc_type)

        if (specialDiscount.special_discounts_doc_type === 'cpf') {
          this.refs.Form.setValue('cpf', specialDiscount.special_discounts_document)
          this.setState({
            showingField: 'cpf',
          })
        } else {
          this.refs.Form.setValue('cnpj', specialDiscount.special_discounts_document)

          this.setState({
            showingField: 'cnpj',
          })
        }
      }, 100)
    }
  }

  componentDidMount() {
    listVariables().then(data => {
      const newState = {
        variableTermUse: _.find(data, {
          key: this.state.school_is_b2c ? 'B2C_TERMS_OF_USE_FILE_PATH' : 'TERMS_OF_USE_FILE_PATH',
        }),
        variablePrivacyPolicy: _.find(data, {
          key: this.state.school_is_b2c ? 'B2C_PRIVACY_POLICY_FILE_PATH' : 'PRIVACY_POLICY_FILE_PATH',
        }),
      }

      this.setState(newState)
    })
  }

  _getFreight(data = null) {
    if (this.state.school_is_b2c) {
      this.props.openLoader()
      const __getVouchers = () => {
        var vouchers = []
        this.props.checkouts.forEach(voucher => {
          let voucher_products = []
          voucher.products.forEach(product => {
            if (product.active) {
              voucher_products.push({ voucher_product_id: product.id })
            }
          })
          vouchers.push({
            number: voucher.voucher,
            voucher_products: voucher_products,
          })
        })
        return vouchers
      }

      if (data) {
        let formated_data = {
          cep: data.cep.replace('-', ''),
          state: data.state,
          city: data.city,
          vouchers: __getVouchers(),
        }

        this.props.getFreight(formated_data)
      } else {
        this.props.resetFreight()
      }
      this.props.closeLoader()
    }
  }

  _modalFreightNotSuported() {
    return (
      <Modal show={this.state.showModalFreight}>
        <Modal.Body>
          <div className="modalHeader">
            <span className="icon-alert" />
            <h1>Atenção</h1>
          </div>

          <div className="modalContent">
            <p className="text-center">Infelizmente não podemos entregar no endereço de entrega digitado!</p>
            <p className="text-center">Por favor digite outro endereço para a entrega.</p>
          </div>

          <div className="modalFooter">
            <button
              className="modal-close"
              onClick={() => {
                this.setState({
                  showModalFreight: false,
                })
              }}
            >
              Entendi
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    let { title, subtitle, swapTo, specialDiscount, checkouts } = this.props
    const has_special_discount = !_.isEmpty(specialDiscount) && !_.isEmpty(specialDiscount.special_discounts_document)
    const first = _.first(checkouts)
    // eslint-disable-next-line
    this.state.school_is_b2c = first ? Boolean(first.school_is_b2c) : false
    return (
      <div className="register">
        {this.state.school_is_b2c && this._modalFreightNotSuported()}
        <Modal show={this.state.showConfirmEmailModal}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Atenção</h1>
            </div>

            <div className="modalContent">
              <p className="text-center">
                Você está se registrando com: <strong>{this.state.email}</strong>
              </p>
              <p className="text-center yellow-text">Sim, esse é meu email.</p>
            </div>

            <div className="modalFooter" style={{ display: 'flex' }}>
              <button className="modal-close" onClick={() => this._handleSubmit()}>
                Confirmar
              </button>
              <button className="modal-support-btn" onClick={() => this.setState({ showConfirmEmailModal: false })}>
                Cancelar
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.school_is_b2c && !this.state.addressModalClose}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Atenção</h1>
            </div>

            <div className="modalContent">
              <p>
                Por favor, certificar o preenchimento do "Endereço de Entrega", pois após a confirmação do pedido o
                endereço não poderá ser alterado.{' '}
              </p>
            </div>

            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => {
                  this.setState({
                    addressModalClose: true,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="register__header">
          <h3 className="register__title">{title}</h3>
          <h4 className="register__subtitle">{subtitle}</h4>
        </div>
        <Form
          ref="Form"
          validate={values => this._validateForm(values)}
          swapTo={swapTo}
          onSubmit={values => {
            if (this.state.school_is_b2c) {
              if (this.props.calculated_freight !== null) {
                this._handleRegister(values)
              } else {
                this.setState({
                  showModalFreight: true,
                })
              }
            } else {
              this._handleRegister(values)
            }
          }}
        >
          {({ submitForm, swapTo, setValue, getValue }) => {
            return (
              <form className="register__form" onSubmit={submitForm}>
                <div className="field">
                  <span className="field__label register__form__label">E-mail</span>
                  <InputText
                    handleBlur={event => {
                      this._getUserByEmail(event)
                    }}
                    className="field__text"
                    field="email"
                  />
                </div>
                <div className="field">
                  <span className="field__label register__form__label">Confirme seu e-mail</span>
                  <InputText blockPaste className="field__text" field="confirm_email" />
                </div>
                <div className="field">
                  <span className="field__label register__form__label">Senha</span>
                  <InputPassword placeholder="" field="password" />
                </div>
                <div className="field">
                  <span className="field__label register__form__label">Confirme sua senha</span>
                  <InputPassword
                    onPaste={event => {
                      event.preventDefault()
                      return false
                    }}
                    placeholder=""
                    field="confirm_password"
                  />
                </div>
                <div className="field">
                  <span className="field__label register__form__label">Nome Completo</span>
                  <Text className="field__text" field="full_name" autoComplete="off" />
                </div>
                <div className="field">
                  <span className="field__label register__form__label">Telefone</span>
                  <InputText
                    field="phone"
                    mask="(99) 9999-9999?"
                    formatChars={{
                      9: '[0-9]',
                      '?': '[0-9]',
                    }}
                    from={/\D/g}
                  />
                </div>
                <div className="address_fields">
                  <span className="field__divider">Endereço para faturamento da nota fiscal</span>
                  <label className="field">
                    <span className="field__label register__form__label">CEP *</span>
                    <div className="zipcodefield">
                      <InputText
                        handleBlur={e => {
                          this._getAddress(e, setValue)
                        }}
                        mask="99999-999"
                        field="address_zipcode"
                        className="field__text"
                      />
                      {this.state.invalid_address_zipcode ? (
                        <div className="FormError invalid-zip">Dados não encontrados para o cep informado</div>
                      ) : null}
                      <a
                        className="register__cep-link"
                        href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm"
                        target="_blank"
                      >
                        Não sabe o CEP?
                      </a>
                    </div>
                  </label>
                  <label className={`field ${!this.state.unique_zipcode ? 'disabled' : ''}`}>
                    <span className="field__label register__form__label">Logradouro
                      <br />
                      <span style={{ fontSize: "12px" }}> Ex: Rua, Avenida, etc.</span>
                    </span>
                    <Text
                      className="field__text"
                      field="address_street"
                      disabled={!this.state.unique_zipcode}
                      autoComplete="off"
                    />
                  </label>
                  <label className={`field ${!this.state.address_found ? 'disabled' : ''}`}>
                    <span className="field__label register__form__label">Número</span>
                    <InputText
                      className="field__text"
                      field="address_number"
                      maxLength="10"
                      handleChange={event => {
                        if (this.state.same_address) {
                          setValue('address_number_delivery', event.target.value, true)
                        }
                      }}
                    />
                  </label>
                  <div className={`field ${!this.state.address_found ? 'disabled' : ''}`}>
                    <span className="field__label register__form__label">Complemento</span>
                    <InputText
                      className="field__text"
                      field="address_complement"
                      maxLength="10"
                      handleChange={event => {
                        if (this.state.same_address) {
                          setValue('address_complement_delivery', event.target.value, true)
                        }
                      }}
                    />
                  </div>
                  
                  {this.state.school_is_b2c && <div
                    className={`field ${!this.state.address_found ? 'disabled' : ''
                      }`}
                  >
                    <span className="field__label register__form__label">Ponto de referência</span>
                    <Text
                      className="field__text"
                      field="address_reference"
                      maxLength="40"
                    />
                  </div>
                  }

                  <div className={`field ${!this.state.unique_zipcode ? 'disabled' : ''}`}>
                    <span className="field__label register__form__label">Bairro</span>
                    <Text
                      className="field__text"
                      field="address_neighborhood"
                      disabled={!this.state.unique_zipcode}
                      autoComplete="off"
                    />
                  </div>
                  
                  <div className={`field ${this.state.disable_address_state ? 'disabled' : ''}`}>
                    <span className="field__label register__form__label">Estado</span>
                    <SelectState
                      className="field__text"
                      field="address_state"
                      disabled={this.state.disable_address_state}
                      autoComplete="off"
                    />
                  </div>
                  <div className="field disabled">
                    <span className="field__label register__form__label">Cidade</span>
                    <Text className="field__text" field="address_city" disabled />
                  </div>
                </div>

                <div className="field">
                  <span className="field__label register__form__label">CPF/CNPJ</span>
                  <RadioGroup
                    field="type"
                    disabled={has_special_discount}
                    errorProps={this.hideDefaultError}
                    errorBefore={false}
                    className="field__radio"
                  >
                    <label className="field__radio__option">
                      <Radio
                        value="cpf"
                        disabled={has_special_discount}
                        onChange={() => {
                          this._toggleTypeField('cpf', setValue)
                          setValue('cnpj', '', true)
                          setValue('ie', null, true)
                          setValue('ie_free', null, true)
                        }}
                      />
                      <span className="field__radio__label">Pessoa física</span>
                    </label>
                    <label className="field__radio__option">
                      <Radio
                        value="cnpj"
                        disabled={has_special_discount}
                        onChange={() => {
                          this._toggleTypeField('cnpj', setValue)
                        }}
                      />
                      <span className="field__radio__label">Pessoa jurídica</span>
                    </label>
                  </RadioGroup>
                  {this.state.showingField === 'cpf' ? (
                    <div className="field--right register__field__cpf">
                      <InputText field="cpf" mask="999.999.999-99" from={/\D/g} />
                    </div>
                  ) : null}
                  {this.state.showingField === 'cnpj' ? (
                    <div className="field--right register__field__cnpj">
                      <InputText field="cnpj" mask="99.999.999/9999-99" from={/\D/g} />
                    </div>
                  ) : null}
                </div>
                {getValue('type') === 'cnpj' ? (
                  <React.Fragment>
                    {!this.state.ie_free ? (
                      <div className="field">
                        <span className="field__label register__form__label">Inscrição Estadual *</span>
                        <InputText field="ie" mask="" from={/\D/g} />
                      </div>
                    ) : null}
                    <div className="field field_ie_free">
                      <label>
                        <Checkbox field="ie_free" className="field__checkbox" onChange={() => this._toggleIE()} />
                        <span className="field__checkbox__label">Isento</span>
                      </label>
                    </div>
                  </React.Fragment>
                ) : null}
                {this.state.school_is_b2c ? (
                  <div className="address_delivery_fields">
                    <span className="field__divider">Endereço de entrega</span>
                    <div className="warning">
                      <span>Atenção:</span> Estes dados não poderão ser alterados após a confirmação do pagamento.
                    </div>
                    <label className="same_address_label">
                      <InputCheckbox
                        onChange={event => this._toggleSameAddress(event, setValue, getValue)}
                        field="same_address_"
                        className="field__checkbox same_address__checkbox"
                        checked={getValue('same_address_') === undefined ? false : !getValue('same_address_')}
                      />
                      <span className="field__label_same_address_">
                        Os dados de entrega são os mesmos dados de faturamento.
                      </span>
                      <FormError field="same_address_" />
                    </label>
                    <label className={`field ${this.state.same_address ? 'disabled' : ''}`}>
                      <span className="field__label register__form__label">CEP *</span>
                      <div className="zipcodefield">
                        <InputText
                          handleBlur={e => {
                            if (!this.state.same_address) {
                              this._getDeliveryAddress(e, setValue)
                            }
                          }}
                          disabled={this.state.same_address}
                          mask="99999-999"
                          field="address_zipcode_delivery"
                          className="field__text"
                        />
                        {this.state.invalid_address_zipcode ? (
                          <div className="FormError invalid-zip">Dados não encontrados para o cep informado</div>
                        ) : null}
                        <a
                          className="register__cep-link"
                          href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm"
                          target="_blank"
                        >
                          Não sabe o CEP?
                        </a>
                      </div>
                    </label>
                    <label
                      className={`field ${!this.state.delivery_unique_zipcode || this.state.same_address ? 'disabled' : ''
                        }`}
                    >
                      <span className="field__label register__form__label">Logradouro
                        <br />
                        <span style={{ fontSize: "12px" }}> Ex: Rua, Avenida, etc.</span>
                      </span>
                      <Text
                        className="field__text"
                        field="address_street_delivery"
                        disabled={!this.state.delivery_unique_zipcode || this.state.same_address}
                      />
                    </label>
                    <label
                      className={`field ${!this.state.delivery_address_found || this.state.same_address ? 'disabled' : ''
                        }`}
                    >
                      <span className="field__label register__form__label">Número</span>
                      <Text
                        className="field__text"
                        field="address_number_delivery"
                        maxLength="10"
                        disabled={this.state.same_address}
                      />
                    </label>
                    <div
                      className={`field ${!this.state.delivery_address_found || this.state.same_address ? 'disabled' : ''
                        }`}
                    >
                      <span className="field__label register__form__label">Complemento</span>
                      <Text
                        className="field__text"
                        field="address_complement_delivery"
                        maxLength="10"
                        disabled={this.state.same_address}
                      />
                    </div>
                    <div
                      className={`field ${!this.state.delivery_address_found || this.state.same_address ? 'disabled' : ''
                        }`}
                    >
                      <span className="field__label register__form__label">Ponto de referência</span>
                      <Text
                        className="field__text"
                        field="address_reference_delivery"
                        maxLength="40"
                        disabled={this.state.same_address}
                      />
                    </div>
                    <div
                      className={`field ${!this.state.delivery_unique_zipcode || this.state.same_address ? 'disabled' : ''
                        }`}
                    >
                      <span className="field__label register__form__label">Bairro</span>
                      <Text
                        className="field__text"
                        field="address_neighborhood_delivery"
                        disabled={!this.state.delivery_unique_zipcode || this.state.same_address}
                      />
                    </div>
                    <div className={`field ${this.state.disable_delivery_address_state ? 'disabled' : ''}`}>
                      <span className="field__label register__form__label">Estado</span>
                      <SelectState
                        className="field__text"
                        field="address_state_delivery"
                        disabled={this.state.disable_delivery_address_state}
                        autoComplete="off"
                      />
                    </div>
                    <div className="field disabled">
                      <span className="field__label register__form__label">Cidade</span>
                      <Text className="field__text" field="address_city_delivery" disabled />
                    </div>
                  </div>
                ) : null}

                <div className="field__terms_of_use_accepted_at register__terms">
                  <label>
                    <Checkbox
                      errorProps={this.hideDefaultError}
                      field="terms_of_use_accepted_at"
                      className="field__checkbox terms__checkbox"
                    />
                    <span className="field__checkbox__label register__terms__label">
                      Li e aceito os{' '}
                      <a href="/termos-de-uso.html" target="_blank">
                        <strong>Termos de Uso</strong>
                        <span className="register__required">*</span>
                      </a>
                    </span>
                    <FormError field="terms_of_use_accepted_at" />
                  </label>
                </div>
                <div className="field__privacy_policy_accepted_at register__terms">
                  <label>
                    <Checkbox
                      errorProps={this.hideDefaultError}
                      field="privacy_policy_accepted_at"
                      className="field__checkbox terms__checkbox"
                    />
                    <span className="field__checkbox__label register__terms__label">
                      Li e aceito as{' '}
                      <a href="/politica-de-privacidade.html" target="_blank">
                        <strong>Políticas de Privacidade</strong>
                        <span className="register__required">*</span>
                      </a>
                    </span>
                    <FormError field="privacy_policy_accepted_at" />
                  </label>
                </div>

                {/*<div className='field__receive_surveys register__terms'>
                  <label>
                    <InputCheckbox
                      field='receive_surveys'
                      className='field__checkbox terms__checkbox'
                      checked={getValue('receive_surveys') === undefined ? false : !getValue('receive_surveys')}
                    />
                    <span className='field__checkbox__label register__terms__label'>
                      Não desejo participar de pesquisas de satisfação de produtos e serviços do Grupo SM
                    </span>
                  </label>
                </div>*/}

                <div className="field__receive_offers register__terms">
                  <label>
                    <InputCheckbox
                      field="receive_offers"
                      className="field__checkbox terms__checkbox"
                      checked={getValue('receive_offers') ? true : false}
                    />
                    <span className="field__checkbox__label register__terms__label">
                      Não desejo receber comunicações comerciais do Grupo SM
                    </span>
                  </label>
                </div>

                <Text type="hidden" field="role" autoComplete="off" />

                <div className="register__buttons">
                  <button
                    className="register__button button__normal--cancel"
                    type="button"
                    onClick={() => {
                      swapTo(0)
                    }}
                  >
                    Cancelar
                  </button>
                  <button className="register__button button__normal" type="submit">
                    Cadastrar
                  </button>
                </div>
              </form>
            )
          }}
        </Form>

        <Modal show={this.state.ieModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Aviso</h1>
            </div>

            <div className="modalContent">
              <p>
                Caso você possua a <span className="yellow-text">Inscrição Estadual Ativa</span> e opte por não
                preencher este campo, a sua nota fiscal <strong>NÃO</strong> será aprovada pelo <strong>SEFAZ</strong>
              </p>
            </div>

            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => {
                  this.setState({
                    ieModalOpen: false,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.errorModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Erro</h1>
            </div>

            <div className="modalContent">
              <p>Ocorreu um erro ao realizar o seu cadastro. Tente novamente mais tarde!</p>
            </div>

            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => {
                  this.setState({
                    errorModalOpen: false,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  checkouts: state.checkout,
  specialDiscount: state.specialDiscount,
  calculated_freight: state.freight.calculated_freight,
})

const mapDispatchToProps = dispatch => ({
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  closeModal: () => dispatch(closeModal()),
  openModal: component => dispatch(openModal(component)),
  getFreight: data => dispatch(getFreight(data)),
  resetFreight: () => dispatch(resetFreight()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterForm))

