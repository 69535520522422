import * as React from "react"
export const AwaitingPaymentIcon = ({props, active = '#CDB04C'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={32}
    fill="none"
    {...props}
  >
    <g stroke={active} clipPath="url(#a)">
      <circle cx={16.6} cy={16} r={15} fill="#fff" strokeWidth={2} />
      <g fill={active} strokeWidth={0.7} clipPath="url(#b)">
        <path d="M24.086 20.692c-.077.28-.207.528-.486.644a1.353 1.353 0 0 1-.503.104c-1.82.007-3.642.005-5.462.005h-7.408c-.659 0-1.016-.354-1.016-1.005V12.4c0-.652.357-1.005 1.016-1.005 4.251 0 8.502.005 12.754-.006.604-.002.984.25 1.105.758v8.545ZM9.805 15.536V20.442c0 .316.098.413.418.413h12.855c.311 0 .414-.099.414-.403V15.69c0-.05-.006-.1-.01-.153H9.806Zm.01-1.78h13.668c.003-.047.009-.09.009-.131v-1.256c-.002-.277-.11-.385-.388-.385h-12.91c-.28 0-.387.108-.389.384-.002.419 0 .837 0 1.256 0 .042.006.084.01.131Z" />
        <path d="M14.573 17.31h2.597c.208 0 .318.072.355.225a.284.284 0 0 1-.232.352c-.057.01-.115.008-.173.008H12.01c-.057 0-.116.002-.173-.008a.292.292 0 0 1-.244-.282.29.29 0 0 1 .251-.289c.057-.008.116-.007.174-.007h2.554ZM13.38 18.491c.484 0 .968-.003 1.452.002.24.002.381.188.306.39-.05.133-.155.192-.293.194-.204.002-.407 0-.61 0H12c-.053 0-.107.002-.159-.007a.293.293 0 0 1-.247-.294.28.28 0 0 1 .263-.277c.232-.011.464-.007.696-.008h.828Z" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.6 0h32v32H.6z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M8.267 8h17v17h-17z" />
      </clipPath>
    </defs>
  </svg>
)
