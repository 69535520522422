import React, { useEffect } from 'react'
import './index.css';
import { TransferListProvider, useTransferList } from '../../hooks/useTransferList';
import { Button } from 'react-bootstrap';
import TransferListItem from './TransferListItem';
import _ from 'lodash';
// import books from './mock.json';

function normalizeBookList(books) {

    let singleBooks, groupBooks = [];

    for (let book of books) {

        if (book.name === 'no_group') {
            singleBooks = [...book.products];
        }
    }

    for (let book of books) {

        if (book.name !== 'no_group') {
            groupBooks.push(book);
        }
    }

    return [...groupBooks, ...singleBooks];
}

export default function TransferList({ groupProducts, setProductsToCreditCard }) {

    const trasnferListHook = useTransferList();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px', padding: '1.3em' }}>
            <h1>Segmentação do pagamento</h1>
            <p className='transferlist-description'>Defina o conjunto de livros do kit a ser pago em cada cartão.</p>
            <TransferListComponent
                trasnferListHook={trasnferListHook}
                groupProducts={groupProducts}
                setProductsToCreditCard={setProductsToCreditCard}
            />
        </div>
    )
}

function TransferListComponent({ groupProducts, trasnferListHook, setProductsToCreditCard }) {

    const {
        handleCheckedRight,
        handleCheckedLeft,
        left,
        right,
        leftChecked,
        rightChecked,
        setLeft
    } = trasnferListHook

    const isDristributedItems = [left.length > 0, right.length > 0];

    useEffect(() => {
        setLeft(normalizeBookList(groupProducts))
    }, [groupProducts])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2em', alignItems: 'center', }}>
            <div className='transfer-list-wrapper'>
                <TransferListItem label={'Cartão 1'} items={left} />
                <div className='transfer-list-controls'>
                    <Button
                        className='transfer-list-button'
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        title="Mover selecionados para esquerda"
                    >
                        &lt;
                    </Button>
                    <Button
                        className='transfer-list-button'
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        titlel="Mover selecionados para direita"
                    >
                        &gt;
                    </Button>
                </div>
                <TransferListItem label={'Cartão 2'} items={right} />
            </div>
            <div>
                <button
                    disabled={!isDristributedItems.every(x => x)}
                    className='button__medium payment__button-boleto'
                    onClick={() => setProductsToCreditCard([left, right])}
                >
                    Continuar
                </button>
            </div>
        </div>
    )
}