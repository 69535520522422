import './index.css'

import { Cell, Column, Table } from 'fixed-data-table-2'
import { Form, Text } from 'react-form'
import React, { Component } from 'react'
import {
  changeFreightAmount,
  changePaymentBilletState,
  changePaymentCreditCardInstallments,
  changePaymentCreditCardState,
  changePaymentTwoCreditCardsState,
  getDefaultData,
} from '@services/sm-api.js'
import { closeLoader, openLoader } from '@actions/loader'

import FilterText from '@components/FilterText'
import InlineLoader from '@components/InlineLoader'
import PermissionsChecker from '@shared/PermissionsChecker'
import _ from 'lodash'
import { connect } from 'react-redux'

const HeaderCell = ({ ...props }) => (
  <Cell className={`report-table__header-cell ${props.className}`} {...props}>
    {props.children}
  </Cell>
)

const DefaultCell = ({ children, className }) => <Cell className={className}>{children}</Cell>

const StateButton = props => {
  return (
    <React.Fragment>
      <PermissionsChecker permissions={['manage_school_payment_options']}>
        <button className={props.className} onClick={props.onClick}>
          {props.children}
        </button>
      </PermissionsChecker>
      <PermissionsChecker permissions={['manage_school_payment_options']} negate>
        <button className={props.className} disabled>
          {props.children}
        </button>
      </PermissionsChecker>
    </React.Fragment>
  )
}

const SelectInstallments = props => {
  return (
    <select value={props.selected} onChange={props.onChange} disabled={!!props.disabled}>
      <option value="0">Padrão</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
    </select>
  )
}

class SchoolPaymentOptions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      schools: [],
      defaultCampaign: null,
      isSearching: false,
    }

    this.pagination = {
      current: 1,
      total: 0,
      isLoading: false,
    }

    this.filter = {}

    this.columns = [
      {
        label: 'ID',
        field: 'id',
        component: DefaultCell,
      },
      {
        label: 'Nome da escola',
        field: 'fantasy_name',
        component: DefaultCell,
      },
      {
        label: 'Boleto',
        field: 'has_billet',
        component: DefaultCell,
        className: 'center',
      },
      {
        label: 'Cartão de crédito',
        field: 'has_credit_card',
        component: DefaultCell,
        className: 'center',
      },
      {
        label: 'Dois Cartões',
        field: 'has_two_credit_cards',
        component: DefaultCell,
        className: 'center',
      },
      {
        label: 'Parcelamento',
        field: 'credit_card_installments',
        component: DefaultCell,
      },
      {
        label: 'Valor do Frete',
        field: 'freight_amount',
        component: DefaultCell,
      },
    ]
  }

  _lazyLoadData = _.debounce((params, filter) => {
    this.setState({ schools: [] })
    this.pagination.current = 1
    return this._loadData(params, filter).finally(() => this.setState({ isSearching: false }))
  }, 300)

  _onSearch = field => {
    this.filter = { ...this.filter, ...field }
    this.setState({ schools: [], isSearching: true })
    return this._lazyLoadData()
  }

  _loadData = () => {
    return getDefaultData('schools/list', {
      page: this.pagination.current,
      ...this.filter,
    })
      .then(response => {
        this.pagination.total = response.pages
        this.pagination.current += 1
        this.setState({ schools: [...this.state.schools, ...response.schools] }, () => {
          this.refs.Form.setAllValues(
            {
              freight_amount: this.state.schools.map(school => school.freight_amount),
            },
            true,
          )
        })

        return response
      })
      .finally(closeLoader)
  }

  _handleLoadMore = () => {
    if (this.pagination.current <= this.pagination.total) {
      this._loadData()
    }
  }

  _handleStateButton = (paymentType, id) => {
    const { openLoader, closeLoader } = this.props

    openLoader()

    if (paymentType === 'billet') {
      return changePaymentBilletState({ id })
        .then(response => {
          if (response.success) {
            this.setState(
              {
                schools: this.state.schools.map(school => {
                  if (school.id === id) {
                    return {
                      ...school,
                      has_billet: !school.has_billet,
                    }
                  } else {
                    return school
                  }
                }),
              },
              () => closeLoader(),
            )
          }
        })
        .finally(closeLoader)
    } else if (paymentType === 'card') {
      return changePaymentCreditCardState({ id })
        .then(response => {
          if (response.success) {
            this.setState(
              {
                schools: this.state.schools.map(school => {
                  if (school.id === id) {
                    return {
                      ...school,
                      has_credit_card: !school.has_credit_card,
                    }
                  } else {
                    return school
                  }
                }),
              },
              () => closeLoader(),
            )
          }
        })
        .finally(closeLoader)
    }else if (paymentType === 'two_cards') {
    return changePaymentTwoCreditCardsState({ id })
      .then(response => {
        if (response.success) {
          this.setState(
            {
              schools: this.state.schools.map(school => {
                if (school.id === id) {
                  return {
                    ...school,
                    has_two_credit_cards: !school.has_two_credit_cards,
                  }
                } else {
                  return school
                }
              }),
            },
            () => closeLoader(),
          )
        }
      })
      .finally(closeLoader)
  }
  }

  _handleInstallments = (id, credit_card_installments) => {
    const { openLoader, closeLoader } = this.props

    openLoader()

    return changePaymentCreditCardInstallments({
      id,
      credit_card_installments,
    })
      .then(response => {
        if (response.success) {
          this.setState(
            {
              schools: this.state.schools.map(school => {
                if (school.id === id) {
                  return {
                    ...school,
                    credit_card_installments,
                  }
                } else {
                  return school
                }
              }),
            },
            () => closeLoader(),
          )
        }
      })
      .catch(err => {
        closeLoader()
      })
      .finally(closeLoader)
  }

  _handleFreightAmount = (id, freight_amount) => {
    const { openLoader, closeLoader } = this.props

    openLoader()

    return changeFreightAmount({
      id,
      freight_amount,
    })
      .then(response => {
        if (response.success) {
          this.setState(
            {
              schools: this.state.schools.map(school => {
                if (school.id === id) {
                  return {
                    ...school,
                    freight_amount,
                  }
                } else {
                  return school
                }
              }),
            },
            () => closeLoader(),
          )
        }
      })
      .catch(err => {
        closeLoader()
      })
      .finally(closeLoader)
  }

  componentDidMount() {
    const { openLoader, closeLoader } = this.props
    openLoader()
    this._loadData().finally(() => {
      closeLoader()
    })
  }

  render() {
    const { schools, isSearching } = this.state

    return (
      <div className={`report special-discount`}>
        <h1 className="report__title">Meios de pagamento</h1>

        <div className="filters" id="report_container">
          <div className="filters__item">
            <FilterText field="search" label="Buscar por ID ou nome da escola" onChange={this._onSearch} />
            <InlineLoader show={isSearching} />
          </div>
        </div>
        <div className="report__table">
          <div className="report-table">
            <div className="report-table__header">
              <h1 className="report-table__title">Escolas</h1>
            </div>
            <Form ref="Form">
              {({ setValue, getValue }) => {
                return (
                  <form>
                    <Table
                      ref="Table"
                      className="report-table"
                      onScrollEnd={this._handleLoadMore}
                      rowHeight={45}
                      rowsCount={schools.length}
                      width={1005}
                      height={400}
                      headerHeight={50}
                      data={schools}
                    >
                      {this.columns.map((column, key) => {
                        return (
                          <Column
                            key={`${column.field}-${key}`}
                            header={<HeaderCell className={column.className}>{column.label}</HeaderCell>}
                            columnKey={column.field}
                            fixed
                            width={960 / this.columns.length}
                            cell={({ rowIndex, columnKey, ...props }) => {
                              const school = schools[rowIndex]
                              const schoolId = school.id
                              return column.field === 'has_billet' ? (
                                <column.component data={schools[rowIndex]} className={column.className}>
                                  <StateButton
                                    className={`btn_state button__small btn_state__${
                                      schools[rowIndex][columnKey] ? 'active' : 'inactive'
                                    }`}
                                    onClick={() => this._handleStateButton('billet', schoolId)}
                                  >
                                    {schools[rowIndex][columnKey] ? 'Ativo' : 'Inativo'}
                                  </StateButton>
                                </column.component>
                              ) : column.field === 'has_credit_card' ? (
                                <column.component data={schools[rowIndex]} className={column.className}>
                                  <StateButton
                                    className={`btn_state button__small btn_state__${
                                      schools[rowIndex][columnKey] ? 'active' : 'inactive'
                                    }`}
                                    onClick={() => this._handleStateButton('card', schoolId)}
                                  >
                                    {schools[rowIndex][columnKey] ? 'Ativo' : 'Inativo'}
                                  </StateButton>
                                </column.component>
                              ) : column.field === 'has_two_credit_cards' ? (
                                <column.component data={schools[rowIndex]} className={column.className}>
                                  <StateButton
                                    className={`btn_state button__small btn_state__${
                                      schools[rowIndex][columnKey] ? 'active' : 'inactive'
                                    }`}
                                    onClick={() => this._handleStateButton('two_cards', schoolId)}
                                  >
                                    {schools[rowIndex][columnKey] ? 'Ativo' : 'Inativo'}
                                  </StateButton>
                                </column.component>
                              ) : column.field === 'credit_card_installments' ? (
                                <column.component data={schools[rowIndex]} className={column.className}>
                                  <React.Fragment>
                                    <PermissionsChecker permissions={['manage_school_payment_options']}>
                                      <SelectInstallments
                                        disabled={!schools[rowIndex]['has_credit_card']}
                                        selected={schools[rowIndex][columnKey]}
                                        onChange={e => this._handleInstallments(schoolId, e.target.value)}
                                      />
                                    </PermissionsChecker>
                                    <PermissionsChecker permissions={['manage_school_payment_options']} negate>
                                      <SelectInstallments disabled selected={schools[rowIndex][columnKey]} />
                                    </PermissionsChecker>
                                  </React.Fragment>
                                </column.component>
                              ) : column.field === 'freight_amount' ? (
                                <column.component data={schools[rowIndex]} className={column.className}>
                                  <React.Fragment>
                                    <PermissionsChecker permissions={['manage_school_freight']}>
                                      <div className="freight_amount_container">
                                        <Text
                                          className="field__text field__freight_amount"
                                          field={[`freight_amount`, rowIndex]}
                                          disabled={!Boolean(school.is_b2c)}
                                          autoComplete="off"
                                        />
                                      </div>

                                      {Boolean(school.is_b2c) &&
                                        getValue([`freight_amount`, rowIndex]) !== school.freight_amount && (
                                          <button
                                            className="button__small button__change_freight"
                                            onClick={e => {
                                              e.preventDefault()
                                              e.stopPropagation()
                                              this._handleFreightAmount(
                                                schoolId,
                                                getValue([`freight_amount`, rowIndex]),
                                              )
                                              return false
                                            }}
                                          >
                                            Ok
                                          </button>
                                        )}
                                    </PermissionsChecker>
                                    <PermissionsChecker permissions={['manage_school_freight']} negate>
                                      <div className="freight_amount_container">
                                        <Text
                                          className="field__text field__freight_amount"
                                          field={[`freight_amount`, rowIndex]}
                                          disabled
                                          autoComplete="off"
                                        />
                                      </div>
                                    </PermissionsChecker>
                                  </React.Fragment>
                                </column.component>
                              ) : (
                                <column.component data={schools[rowIndex]} className={column.className}>
                                  {schools[rowIndex][columnKey]}
                                </column.component>
                              )
                            }}
                          />
                        )
                      })}
                    </Table>
                  </form>
                )
              }}
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.sessionReducer.user,
})

const mapDispatchToProps = { openLoader, closeLoader }

export default connect(mapStateToProps, mapDispatchToProps)(SchoolPaymentOptions)

