import * as React from "react";
export const AwaitingShippingIcon = ({ props, active}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={32}
    fill="none"
    {...props}
  >
    <circle
      cx={16.8}
      cy={16}
      r={15}
      fill="#fff"
      stroke={active}
      strokeWidth={2}
    />
    <g
      stroke={active}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#a)"
    >
      <path d="M12.009 10.125h9.916v12.75l-.35-.301a2 2 0 0 0-2.604 0l-.351.301-.351-.301a2 2 0 0 0-2.603 0l-.352.301-.35-.301a2 2 0 0 0-2.604 0l-.351.301v-12.75ZM19.092 12.958h-4.25M19.092 15.792h-4.25M19.092 18.625H16.26" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8.467 8h17v17h-17z" />
      </clipPath>
    </defs>
  </svg>
);
