import React from 'react'

import moment from 'moment'
import { AwaitingShippingIcon } from './components/TrackingIconStatus/awaiting-shipping-icon'
import { PaymentConfirmedIcon } from './components/TrackingIconStatus/payment-confirmed-icon'
import { OrderPlacedIcon } from './components/TrackingIconStatus/order-placed-icon'
import { PreparationOrderIcon } from './components/TrackingIconStatus/preparation-order-icon copy'
import { OrderDeliveredIcon } from './components/TrackingIconStatus/order-delivered-icon'
import { CanceledOrderIcon } from './components/TrackingIconStatus/canceled-order-icon'
import { DeliveredShippingIcon } from './components/TrackingIconStatus/delivered-shipping-icon'
import { AwaitingPaymentIcon } from './components/TrackingIconStatus/awaiting-payment-icon'


const TrackingTimeline = ({ subOrder }) => {

  let history = subOrder.history;

  const statusList = [
    { status: 'NEW', color: '#309740', sort: 1, icon: OrderPlacedIcon, label: 'Pedido realizado', disabled: true, hidden: false },
    { status: 'PW', color: '#CDB04C', sort: 2, icon: AwaitingPaymentIcon, label: 'Aguardando pagamento', disabled: true, hidden: false },
    { status: 'AP', color: '#309740', sort: 3, icon: PaymentConfirmedIcon, label: 'Pagamento confirmado', disabled: true, hidden: false },
    { status: 'PP', color: '#309740', sort: 4, icon: PreparationOrderIcon, label: 'Pedido em separação', disabled: true, hidden: false },
    { status: 'PF', color: '#309740', sort: 5, icon: AwaitingShippingIcon, label: 'Aguardando transportadora', disabled: true, hidden: false },
    { status: 'PT', color: '#309740', sort: 6, icon: DeliveredShippingIcon, label: 'Entregue à transportadora', disabled: true, hidden: false },
    { status: 'PE', color: '#309740', sort: 8, icon: OrderDeliveredIcon, label: 'Entregue', disabled: true, hidden: false },
    { status: 'C', color: '#ED1B2F', sort: 9, icon: CanceledOrderIcon, label: 'Pedido cancelado', disabled: true, hidden: false },
  ]

  const isCanceled = history.some(item => item.status === 'C' || item.status === 'CM');
  const isAwaitPayment = history.some(item => item.status === 'PW' || item.status === 'BAP');
  const paymentConfirmed = history.some(item => item.status === 'AP');

  statusList.map(x => {

    if (isAwaitPayment) {
      if (x.status === 'PW') {
        x.disabled = false;
        x.color = '#CDB04C';
      }
    }

    if (x.status === 'PW') {
      if (!paymentConfirmed) {
        x.color = '#CDB04C';
        x.disabled = false;

      } else {
        x.color = '#309740';
        x.disabled = false;
      }
    }

    if (x.status === 'C') {
      if (history.some(i => i.status === 'CM')) {
        x.label = 'Pedido cancelado manualmente';
      } else if (history.some(i => i.status === 'C')) {
        x.label = 'Pedido cancelado';
      }
      x.disabled = false;
    }
  })

  let sortedStatuslist = statusList.sort((a, b) => a.sort - b.sort).filter(x => !x.hidden);

  // subOrder.school_is_b2c = 1;
  // subOrder.business_line_id =6;

  let filteredStatusList = sortedStatuslist.filter(status => {

    if (isCanceled) return status.status === 'NEW' || status.status === 'C'

    if (!subOrder.school_is_b2c) return status.status === 'NEW' || status.status === 'PW' || status.status === 'AP'

    if (subOrder.business_line_id === 7 || !subOrder.school_is_b2c) return status.status === 'NEW' || status.status === 'PW' || status.status === 'AP'

    return status.status !== 'C';

  })

  for (let status of filteredStatusList) {
    for (let item of history) {
      if (status.status === item.status) {
        status.disabled = false;
        status.changed = item.changed;
      }
    }
  }

  return (
    <div className="tracking-timeline">
      <ul className={'tracking-timeline__steps'}>
        {filteredStatusList.map((status, index) => {
          return !status.hidden && <TrackingStep
            key={index}
            status={status}
          />
        })}
      </ul>
    </div>
  )
}

const TrackingStep = ({ status }) => {

  const Icon = status.icon;

  return (
    <li className={`tracking-timeline__step ${status.disabled && 'disabled-step'}`}>
      <div className="tracking-timeline__step-details">
        <div className="circle">
          {status.disabled ? <Icon active={'#ddd'} /> : <Icon active={status.color} />}
        </div>
        <div className="tracking-timeline__step-info">
          <p className={`status ${status.disabled && 'text-disabled'}`}>{status.label}</p>
          {!status.disabled && <p className="date">{moment(status.changed).format('DD/MM/YYYY')}</p>}
        </div>
      </div>
    </li>
  )
}

export default TrackingTimeline

