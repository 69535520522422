import _ from 'lodash';
import React, { createContext, useContext } from 'react'

const TransferListContext = createContext({});

export function useTransferList() {

    const context = useContext(TransferListContext);

    if (context === undefined) throw new Error('useTransferList must be used within a TransferListProvider')

    return { ...context };
}

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export function TransferListProvider({ children }) {

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => {

        const currentIndex = checked.indexOf(value);

        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const toggleAll = (side, element) => {

        let checkAll = [...checked];
        if (element.target.checked) {
            checkAll.push(...side);
        } else {
            checkAll = checked.filter(item => !side.includes(item));
        }

        setChecked(_.uniq(checkAll));
    }

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const values = {
        handleToggle,
        handleAllRight,
        handleCheckedRight,
        handleCheckedLeft,
        handleAllLeft,
        toggleAll,
        setLeft,
        left,
        right,
        checked,
        leftChecked,
        rightChecked,
    }

    return (
        <TransferListContext.Provider value={values}>
            {children}
        </TransferListContext.Provider>
    )
}