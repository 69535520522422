import * as React from "react";
export const CanceledOrderIcon = ({props, active = '#ED1B2F'}) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_505_1776)">
      <circle
        cx={16}
        cy={16}
        r={15}
        fill="white"
        stroke={active}
        strokeWidth={2}
      />
      <path
        d="M11.7399 12.0729L20.5941 20.9271M16.167 22.875C12.6462 22.875 9.79199 20.0208 9.79199 16.5C9.79199 12.9792 12.6462 10.125 16.167 10.125C19.6878 10.125 22.542 12.9792 22.542 16.5C22.542 20.0208 19.6878 22.875 16.167 22.875Z"
        stroke={active}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_505_1776">
        <rect width={32} height={32} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
