const cardTemplate = {
    card_number: '',
    name: '',
    card_expire: '',
    cvv: '',
    num_parcela: '',
    issuer: null,
    maxLength: 16,
    isValid: false,
    paymentError: null,
    paymentErrorCode: null,
    order_id: null,
    cardRegex: { number: '9999 9999 9999 9999', cvv: '999' },
    processing: false,
    showDuplicatedOrderModal: false,
    showDuplicatedOrderAlertModal: false,
    isDone: false,
    products: []
}

export default cardTemplate;