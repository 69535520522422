import { Message, Messaging } from '@shared/Messaging'
import React, { Component } from 'react'
import { clearVoucher, requestVoucher } from '@actions/preVoucher'
import { insertStudent, resetAllWarnings, resetWarning } from '@actions/checkout'

import _ from 'lodash'
import { activateVoucherForm } from '@actions/voucherForm'
import { connect } from 'react-redux'
import { updateActiveVoucher } from '@actions/activeVoucher'
import { withRouter } from '../../utils/withRouter'
import { Modal } from 'react-bootstrap'

class VoucherForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isInputsValid: false,
      isNameEmpty: false,
      isVoucherEmpty: false,
      showNameError: false,
      showEmptyVoucherError: false,
      nameErrorMessage: '',
      isSameSchool: true,
      exists: false,
      message: '',
      modalAlert2Cards: true,
      acceptButtonModal: false,
    }

    this._checkErrors = this._checkErrors.bind(this)
    this._handleOnBlur = this._handleOnBlur.bind(this)
    this._handleOnSelectedVoucher = this._handleOnSelectedVoucher.bind(this)
    this._handleOnSelectedDiscount = this._handleOnSelectedDiscount.bind(this)
    this._handleCancel = this._handleCancel.bind(this)
  }

  _handleOnBlur() {
    this.props.clear()
    this.props.requestVoucher(this.refs.voucher.value)
  }

  _checkSameSchool() {
    const preVoucherSapCode = this.props.preVoucher.school_sap_code
    const checkout = this.props.checkout || []

    if (checkout.length > 0 && preVoucherSapCode && checkout[0].school_sap_code !== preVoucherSapCode) {
      this.setState({ isSameSchool: false })
      return false
    }

    return true
  }

  _checkErrors() {
    let valid = true

    let exists = false

    let name = this.refs.name.value.trim()

    let voucher = this.refs.voucher.value.trim()

    let checkout = this.props.checkout

    let errorMessage = ''

    for (let i = 0; i < checkout.length; i++) {
      if (
        checkout[i].name.toLowerCase() === name.toLowerCase() &&
        checkout[i].voucher.toLowerCase() === voucher.toLowerCase()
      ) {
        valid = false
        errorMessage = 'Esse nome já foi incluído para o voucher informado.'
        exists = true
        break
      }
    }

    if (this.props.preVoucher.error.message || !name.length) {
      valid = false
      errorMessage = 'Preencha o nome do aluno'
    }

    if (name.match(/[^A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]/g)) {
      valid = false
      errorMessage = 'O nome não pode conter números'
    }

    if (name.split(' ').length < 2) {
      valid = false
      errorMessage = 'Por favor, preencha o nome completo'
    }

    if (!this._checkSameSchool()) {
      valid = false
    }

    this.setState({
      showNameError: !valid,
      nameErrorMessage: errorMessage,
      isInputsValid: valid,
      exists: exists,
    })
  }

  _handleOnSelectedDiscount() {
    let showNameError = false
    let nameErrorMessage = ''
    let student_name = this.refs.name.value

    if (_.isEmpty(student_name)) {
      showNameError = true
    }

    const available_special_discount_vouchers = this.props.specialDiscount.special_discounts.filter(
      discount =>
        !_.find(this.props.checkout, {
          special_discount_id: Number(discount.special_discount_id),
        }),
    )

    let discount = null

    for (let available_discount of available_special_discount_vouchers) {
      if (available_discount.student_name.toLowerCase().trim() === student_name.toLowerCase().trim()) {
        discount = available_discount
        break
      }
    }

    if (!discount) {
      showNameError = true
      nameErrorMessage = 'Não foi encontrado nenhum desconto para este nome de aluno'
    }

    this.setState({ showNameError, nameErrorMessage }, () => {
      if (!discount) return
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'add_aluno',
        eventAction: 'click',
        eventLabel: 'box_lateral_confirmar',
      })
      this.props.insertDiscount(discount)
      this.props.navigate('/steps/selecionar-livros')
    })
  }

  _handleOnSelectedVoucher() {
    let showNameError = this.refs.name.value.length === 0
    let showEmptyVoucherError = this.refs.voucher.value.length === 0

    this.setState({ showEmptyVoucherError, showNameError }, () => {
      if (!this.state.isInputsValid) return
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'add_aluno',
        eventAction: 'click',
        eventLabel: 'box_lateral_confirmar',
      })

      this.props.insertStudent(this.refs.name.value)
      this.props.navigate('/steps/selecionar-livros')
    })
  }

  _handleCancel() {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'add_aluno',
      eventAction: 'click',
      eventLabel: 'box_lateral_cancelar',
    })

    this.props.firstStudent()
  }

  render() {
    const { preVoucher, specialDiscount } = this.props
    const has_special_discount = !_.isEmpty(specialDiscount) && !_.isEmpty(specialDiscount.special_discounts)

    return (
      <div className="voucher-form">
        <h1 className="voucher-form__title">Novo aluno</h1>
        {has_special_discount ? (
          <React.Fragment>
            <input
              className="special_discount__input"
              type="text"
              placeholder="Digite aqui o nome do aluno"
              ref="name"
              autoComplete="off"
              onKeyPress={e => {
                if (e.key === 'Enter') this._handleBuyButton.apply(this, [e])
              }}
            />
            <Message expr={this.state.showNameError}>
              <span className="voucher-form__info-text--error">{this.state.nameErrorMessage}</span>
            </Message>
            <div className="voucher-form__footer">
              <button
                className="button__normal voucher-form__cancel"
                onClick={this._handleCancel}
                placeholder="Qual é o nome do aluno?"
              >
                Cancelar
              </button>
              <button className="button__normal voucher-form__ok" onClick={this._handleOnSelectedDiscount}>
                Selecionar produtos
              </button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <input
              type="text"
              ref="voucher"
              onBlur={this._handleOnBlur}
              autoComplete="off"
              onChange={() => {
                this.setState({ showEmptyVoucherError: false, exists: false })
              }}
              placeholder="Digite aqui o número do voucher"
              className={`voucher-form__input${preVoucher.error.message || this.state.showEmptyVoucherError ? '--error' : ''
                }`}
            />

            <Modal show={this.state.modalAlert2Cards}>
              <Modal.Body>
                <div className="modalHeader">
                  <span className="icon-alert" />
                  <h1>Atenção</h1>
                </div>

                <div className="modalContent">
                  <ul>
                    <li style={{ fontSize: '18px' }}>
                      A inclusão de um novo aluno no pedido inviabiliza o pagamento em dois cartões do kit educamos. Caso deseje dividir a compra, por favor realize compras separadas. Caso seja necessária a compra em dois cartões, faça a compra de cada aluno separadamente.
                    </li>
                    <li style={{ fontSize: '18px' }}>O frete é calculado com base no peso do pedido, logo a compra em pedidos separados não interferirá no valor final da sua compra.</li>
                  </ul>
                      <label className="checkbox_area">
                        <input
                          onClick={() => {
                            this.setState({
                              acceptButtonModal: !this.state.acceptButtonModal
                            })
                          }}
                          type="checkbox" className="checkbox" autoComplete="off" />
                        <span className="checkbox__label">Estou ciente das regras e desejo continuar</span>
                      </label>
                  </div>
                  
                <div className="modalFooter">
                  <button
                    className="inline-button"
                    onClick={this._handleCancel}
                  >
                    Cancelar
                  </button>
                  <button
                    className={`modal-close inline-button ${this.state.acceptButtonModal ? 'accept' : 'disabled'}`}
                    disabled={!this.state.acceptButtonModal}
                    onClick={() => {
                      this.setState({
                        modalAlert2Cards: false
                      })
                    }}>
                    Entendi
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            <Messaging>
              <Message expr={preVoucher.school_name && !preVoucher.error.message}>
                <div className="voucher-form__info-container">
                  <div className="" style={{ textAlign: 'center' }}>
                    <img alt="" src={preVoucher.logo} style={{ height: '36px', width: 'auto' }} />
                  </div>
                  <span className="voucher-form__info-text">
                    {preVoucher.school_name} | {preVoucher.school_address} - {preVoucher.study_year}{' '}
                    {preVoucher.study_step}
                  </span>
                  <span className="voucher-form__info-text">Voucher válido até {preVoucher.contract_end}</span>
                </div>
              </Message>
              <Message expr={preVoucher.error.message !== null}>
                <span className="voucher-form__info-text--error">{preVoucher.error.message}</span>
              </Message>
              <Message expr={this.state.showEmptyVoucherError}>
                <span className="voucher-form__info-text--error">Preencha o Voucher</span>
              </Message>

              <Message expr={!this.state.isSameSchool}>
                <span className="voucher-form__info-text--error">
                  O voucher informado não é da mesma escola. Por favor, use-o em um novo pedido.
                </span>
              </Message>
            </Messaging>
            <input
              type="text"
              ref="name"
              autoComplete="off"
              placeholder="Qual é o nome do aluno?"
              className={`voucher-form__input${this.state.showNameError || this.state.exists ? '--error' : ''}`}
              onChange={() => {
                this.setState({ showNameError: false, exists: false })
                this._checkErrors()
              }}
            />
            <Message expr={this.state.showNameError}>
              <span className="voucher-form__info-text--error">{this.state.nameErrorMessage}</span>
            </Message>
            <div className="voucher-form__footer">
              <button
                className="button__normal voucher-form__cancel"
                onClick={this._handleCancel}
                placeholder="Qual é o nome do aluno?"
              >
                Cancelar
              </button>
              <button
                className="button__normal voucher-form__ok"
                onClick={this._handleOnSelectedVoucher}
                placeholder="Qual é o nome do aluno?"
              >
                Selecionar produtos
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  specialDiscount: state.specialDiscount,
  preVoucher: state.preVoucher,
  checkout: state.checkout,
})

const mapDispatchToProps = dispatch => ({
  requestVoucher: voucher => dispatch(requestVoucher(voucher)),
  insertStudent: student => dispatch(insertStudent(student)),
  hideForm: () => dispatch(activateVoucherForm(false)),
  updateActiveVoucher: (voucher, name) => dispatch(updateActiveVoucher(voucher, name)),
  clear: () => dispatch(clearVoucher()),
  resetWarning: voucher => dispatch(resetWarning(voucher)),
  resetAllWarnings: () => dispatch(resetAllWarnings()),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  preVoucher: stateProps.preVoucher,
  specialDiscount: stateProps.specialDiscount,
  requestVoucher: dispatchProps.requestVoucher,
  clear: dispatchProps.clear,
  checkout: stateProps.checkout,
  firstStudent: () => {
    dispatchProps.hideForm()
    dispatchProps.updateActiveVoucher(stateProps.checkout[0].voucher, stateProps.checkout[0].name)
  },
  insertStudent: name => {
    dispatchProps.insertStudent({ ...stateProps.preVoucher, name })
    dispatchProps.hideForm()
    dispatchProps.updateActiveVoucher(stateProps.preVoucher.voucher, name)
    dispatchProps.resetAllWarnings()
    dispatchProps.clear()
  },
  insertDiscount: discount => {
    dispatchProps.insertStudent({ ...discount, name: discount.student_name })
    dispatchProps.hideForm()
    dispatchProps.updateActiveVoucher(discount.voucher, discount.student_name)
    dispatchProps.resetAllWarnings()
    dispatchProps.clear()
  },
  navigate: ownProps.navigate,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(VoucherForm))

