import * as React from "react";
export const PreparationOrderIcon = ({props, active = '#309740'}) => (
  <svg
    width={33}
    height={32}
    viewBox="0 0 33 32"
    fill="none"  
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={16.2002}
      cy={16}
      r={15}
      fill="white"
      stroke={active}
      strokeWidth={2}
    />
    <g clipPath="url(#clip0_505_1611)">
      <path
        d="M10.7002 13.667H22.0335V20.167C22.0335 21.2716 21.1381 22.167 20.0335 22.167H12.7002C11.5956 22.167 10.7002 21.2716 10.7002 20.167V13.667Z"
        stroke={active}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5335 10.833H19.2002L22.0335 13.6663H10.7002L13.5335 10.833Z"
        stroke={active}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5342 16.5H16.3675"
        stroke={active}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_505_1611">
        <rect
          width={17}
          height={17}
          fill="white"
          transform="translate(7.86719 8)"
        />
      </clipPath>
    </defs>
  </svg>
);
