import React, { Component } from 'react'


export default class CreditCardReasonsTwocards extends Component {

  componentDidMount() {
    document.getElementById('messageErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  render() {
    return (
      <div id='messageErrorBlock' style={
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }
      }>
      <div className="reasons__container" style={
        {
          maxWidth: '700px',
        }
      }>
        <p>Informamos que sua compra foi cancelada por falta de autorização da operadora de cartão de crédito em um dos cartões ou ambos. Caso seja realizado algum débito no cartão <b>seu estorno será realizado automaticamente</b> conforme as regras do seu banco.</p>
        <p>Verifique os motivos mais comuns:</p>
        <ul>
          <li>1. Problemas na digitação do código de segurança do cartão;</li>
          <li>2. Operadora bloqueou o cartão por tratar-se de uma compra incomum em sua fatura;</li>
          <li>3. Nome do titular do cartão não foi digitado exatamente como está no cartão</li>
          <li>4. Cartão não foi desbloqueado;</li>
          <li>5. Valor da compra não aprovado. (No caso de pagamento em várias parcelas, o valor total da compra não pode exceder o limite do seu cartão. Esta é uma regra de aprovação adotada pelas administradoras de cartões de crédito.)</li>
        </ul>
      </div>
      </div>
    )
  }
}


