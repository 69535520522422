import React from 'react'
import { Form, Select, Text } from 'react-form'
import { Component } from 'react'
import { checkoutTotal, checkoutTotalDiscounted, clear, filterActiveProducts } from '@actions/checkout'
import { closeLoader, openLoader } from '@actions/loader'
import { requestInstallments, requestOrder } from '@actions/creditCard'

import Cards from 'react-credit-cards'
import CreditCardReasons from '@components/CreditCardReasons'
import CustomText from '@shared/CustomText'
import _ from 'lodash'
import { connect } from 'react-redux'
import creditMasks from './credit-masks'
import valid from 'card-validator'
import { Navigate } from 'react-router-dom'
import { DuplicatedOrderModal } from '../../shared/DuplicatedOrderModal'
import { DuplicatedOrderAlertModal } from '../../shared/DuplicatedOrderAlertModal'
import { getOrdersByUser } from '../../services/sm-api'
import { useState } from 'react'


export default function CreditCardForm({ control, index, children }) {

  const { setState, state, checkAlreadyPurchase, _generateInstalmentOptions, creditCard } = control;
  
  const handleFormChange = (obj) => {

    const updatedCards = [...state.cards];
    updatedCards[index] = { ...state.cards[index], ...obj };
    setState({ cards: updatedCards });

  };

  return (
    <Form
      onSubmit={checkAlreadyPurchase}
      onChange={({ values }) => {

        if (!_.isEmpty(values)) {

          handleFormChange(values)

          let result = valid.number(values.card_number)

          if (result.card) {

            setState({
              cardRegex: {
                number: creditMasks(result.card.type).number,
                cvv: creditMasks(result.card.type).cvv,
              },
            })

          }
        }
      }}
      validate={({ card_number, name, card_expire, cvv, num_parcela }) => {

        let card = valid.number(card_number)

        return {
          card_number: !card.isValid ? 'Número inválido' : undefined,
          name: !name ? 'Nome é obrigatório.' : undefined,
          card_expire: !card_expire ? 'Validade é obrigatório' : undefined,
          cvv: !cvv ? 'CVV é obrigatório' : undefined,
          num_parcela: !num_parcela ? 'Selecione uma parcela.' : undefined,
        }
      }}

    >
      {({ values, submitForm }) => {
        return (
          <form
            onSubmit={submitForm}
            onChange={() =>
              setState({
                paymentError: null,
                paymentErrorCode: null,
              })
            }
          >
            {state.cards.length > 1 && <div className="field card-register__field">
              <span className="field__label card-register__label">Total Cartão {index + 1}</span>
              <div className={'FormInput'}>
                <input
                  id="payment-name-input"
                  className="field__text card-register__input"
                  field={`name`}
                  style={{ background: '#EBEBEB' }}
                  disabled
                  value={`R$ ${state.cards[index].total} `}
                  autoComplete="off"
                />
              </div>
            </div>}
            <div className="field card-register__field">
              <span className="field__label card-register__label">Número cartão</span>
              <CustomText
                id="payment-card-number-input"
                className="field_text card-register__input"
                field={`card_number`}
                mask={state.cardRegex.number}
                regex={/\s/g}
                onFocus={() => setState({ focused: `card_number` })}
              />
            </div>
            <div className="field card-register__field">
              <span className="field__label card-register__label">Nome</span>
              <Text
                id="payment-name-input"
                className="field__text card-register__input"
                field={`name`}
                autoComplete="off"
                onFocus={() => setState({ focused: `name` })}
              />
            </div>
            <div className="field card-register__container">
              <div className="card-register__container__field--expiry">
                <span className="field__label card-register__label">Validade</span>
                <CustomText
                  id="payment-validity-input"
                  className="field__text"
                  field={`card_expire`}
                  mask="99/99"
                  onFocus={() => setState({ focused: `expiry` })}
                />
              </div>
              <div className="card-register__container__field--cvv">
                <span className="field__label">CVV</span>
                <CustomText
                  id="payment-cvv-input"
                  className="field__text card-register__cvv"
                  field={`cvv`}
                  mask={'999'}
                  onFocus={() => setState({ focused: `cvv` })}
                />
              </div>
            </div>
            <div className="field card-register__select-field">
              <span className="field__label card-register__label">Parcelas</span>
              <div className="field__select card-register__select">
                <Select
                  id="payment-installment-select"
                  placeholder="Selecione"
                  field={`num_parcela`}
                  value="0"
                  options={_generateInstalmentOptions(state.cards[index].installments)}
                />
              </div>
            </div>
          </form>
        )
      }}
    </Form>

  )
}
