import * as React from "react";
export const DeliveredShippingIcon = ({props, active = '#309740'}) => (
  <svg
    width={33}
    height={32}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={16.4004}
      cy={16}
      r={15}
      fill="white"
      stroke={active}
      strokeWidth={2}
    />
    <path
      d="M18.4799 18.9286H13.8468M18.4799 18.9286V14.375M18.4799 18.9286H19.288M11.638 18.9286H11.1924C10.6401 18.9286 10.1924 18.4809 10.1924 17.9286V13.25C10.1924 12.6977 10.6401 12.25 11.1924 12.25H17.4799C18.0322 12.25 18.4799 12.6977 18.4799 13.25V14.375M18.4799 14.375H21.0299L22.9424 16.5V18.9286H21.4968M14.0174 19.5357C14.0174 20.2063 13.4465 20.75 12.7424 20.75C12.0382 20.75 11.4674 20.2063 11.4674 19.5357C11.4674 18.8651 12.0382 18.3214 12.7424 18.3214C13.4465 18.3214 14.0174 18.8651 14.0174 19.5357ZM21.6674 19.5357C21.6674 20.2063 21.0965 20.75 20.3924 20.75C19.6882 20.75 19.1174 20.2063 19.1174 19.5357C19.1174 18.8651 19.6882 18.3214 20.3924 18.3214C21.0965 18.3214 21.6674 18.8651 21.6674 19.5357Z"
      stroke={active}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
