import React from 'react'
import { useTransferList } from '../../hooks/useTransferList';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

export default function TransferListItem({ items, label }) {

    const { handleToggle, checked, toggleAll } = useTransferList();

    const freightAmount = useSelector(state => state.freight.calculated_freight);
    const totalAmount =  label === 'Cartão 1' ?  (freightAmount + Math.floor(_.sumBy(items, 'amount_discounted') * 100) / 100).toFixed(2): 
    (Math.floor(_.sumBy(items, 'amount_discounted') * 100) / 100).toFixed(2);
    return (
        <div>
            <div className='transfer-list-name'>{label}</div>
            <div className='transfer-list-container'>
                <div className='select-all-items'>
                    <div>
                        <input
                            className='field__checkbox'
                            onChange={(e) => toggleAll(items, e)}
                            type='checkbox'
                            disabled={items.length === 0}
                            checked={items.length > 0 && items.every(item => checked.indexOf(item) !== -1)}
                        />
                    </div>
                    Selecionar todos
                </div>
                <div className='transfer-list-header'>
                    <span style={{ flex: '1' }}>Livros</span>
                    <span>Valor</span>
                </div>
                <ul className='transfer-list'>
                    {
                        items.map((value, index) => <li key={index} onClick={() => handleToggle(value)} className='transfer-list-item'>
                            <div className='transfer-list-label'>
                                <div>
                                    <input
                                        checked={checked.indexOf(value) !== -1}
                                        onChange={() => handleToggle(value)}
                                        className='field__checkbox'
                                        type={'checkbox'}
                                    />
                                </div>
                                <strong>{value.name}</strong>
                            </div>
                            <div className='transfer-list-value'>{(Math.floor(value.amount_discounted * 100) / 100).toFixed(2)}</div>
                        </li>)
                    }
                     {label === 'Cartão 1' && <li className='transfer-list-item'>
                            <div className='transfer-list-label'>
                                <div>
                                    <input
                                        disabled
                                        className='field__checkbox'
                                        type={'checkbox'}
                                    />
                                </div>
                                <strong>Frete</strong>
                            </div>
                            <div className='transfer-list-value'>{freightAmount || 'Grátis'}</div>
                        </li>}
                </ul>
                <div className='transfer-list-footer'>
                    <span>Total {label}</span>
                    <span>R$ {totalAmount} </span>
                </div>
            </div>
        </div>
    )
}
