import { Form, Select, Text } from 'react-form'
import React, { Component } from 'react'
import { checkoutTotal, checkoutTotalDiscounted, clear, filterActiveProducts } from '@actions/checkout'
import { closeLoader, openLoader } from '@actions/loader'
import { requestInstallments, requestOrder } from '@actions/creditCard'

import Cards from 'react-credit-cards'
import CreditCardReasons from '@components/CreditCardReasons'
import CustomText from '@shared/CustomText'
import _ from 'lodash'
import { connect } from 'react-redux'
import creditMasks from './credit-masks'
import valid from 'card-validator'
import { Navigate } from 'react-router-dom'
import { DuplicatedOrderModal } from '../../shared/DuplicatedOrderModal'
import { DuplicatedOrderAlertModal } from '../../shared/DuplicatedOrderAlertModal'
import { getOrdersByUser } from '../../services/sm-api'
import CreditCardForm from './CreditCardForm'
import cardTemplate from './card-template-state';
import CreditCardReasonsTwocards from '../CreditCardReasons/twoCards'

class CreditCard extends Component {
  purchasedOrder = ''

  constructor(props) {
    super(props)
    this._isMounted = false
  }

  state = {
    card_number: '',
    name: '',
    card_expire: '',
    cvv: ' ',
    num_parcela: '',
    issuer: null,
    maxLength: 16,
    isValid: false,
    paymentError: null,
    paymentErrorCode: null,
    order_id: null,
    cardRegex: { number: '9999 9999 9999 9999 ', cvv: '999' },
    processing: false,
    showDuplicatedOrderModal: false,
    showDuplicatedOrderAlertModal: false,
    isDone: false,
    cards: [],
    is_two_cards: false,
  }

  createCards = async () => {
    const cards = [];

    if (this.props.componentProps.products) {

      const distributedProducts = this.props.componentProps.products;

      for (let index in distributedProducts) {

        cards[index] = { ...cardTemplate }

        cards[index].products = [];

        for (let j in distributedProducts[index]) {
          if (distributedProducts[index][j].products) {
            distributedProducts[index][j].products.map(x => cards[index].products.push(x))
          } else {
            cards[index].products.push(distributedProducts[index][j])
          }
        }

        const totalProducts = (Math.floor(_.sumBy(cards[index].products, 'amount_discounted') * 100) / 100);

        const totalAmount = index === '0' ? totalProducts + this.props.freightAmount : totalProducts;
        
        cards[index].total = totalAmount.toFixed(2);
        const { payload } = await this.props.getInstallments(totalAmount, this.props.schoolSapCode);
        cards[index].installments = payload;
      }

      this.setState({ cards: cards, is_two_cards: true });

      return;
    }

    const { payload } = await this.props.getInstallments(this.props.orderTotal + this.props.freightAmount, this.props.schoolSapCode);
    cardTemplate.installments = payload;
    cardTemplate.total = this.props.orderTotal;

    const newCards = Array.from({ length: 1 }, () => ({ ...cardTemplate }));

    this.setState(prevState => ({
      cards: [...prevState.cards, ...newCards]
    }), () => {
    });

    this.props.getInstallments(this.props.orderTotal + this.props.freightAmount, this.props.schoolSapCode)

  };

  _generateInstalmentOptions(installments = []) {
    installments = Object.keys(installments).reduce((prev, value) => {
      prev[value - 1] = {
        label: installments[value],
        value: +value,
      }
      return prev
    }, [])

    return installments
  }

  _handleOnSubmit = () => {
    if (this.state.processing === true) {
      return false
    }
    this.setState({ processing: true })

    window.ga('send', {
      hitType: 'event',
      eventCategory: 'compra',
      eventAction: 'click',
      eventLabel: 'enviou_cartao_5.1',
    })

    return this.props
      .requestOrder(this.state.cards)
      .then(response => {
        const { status, error = {} } = response.payload
        this.props.closeLoader()

        //eslint-disable-next-line
        if (this._isMounted && error.status > 0) {

          if (error.error_type == 'duplicated_students') {
            this.setState({ showDuplicatedOrderAlertModal: true })
          }

          this.setState({ order_id: null })
          this.setState({ paymentError: error.message })
          this.setState({ paymentErrorCode: error.status })
        }
        //eslint-disable-next-line
        if (status === 0) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'compra',
            eventAction: 'click',
            eventLabel: 'confirmou_cartao_5.1',
          })
          this.props.clear()
          this.setState({ isDone: true })
        }
        this.setState({ processing: false })
      })
      .catch(error => {
        this.setState({ processing: false })
        this.props.closeLoader()
        this._isMounted && this.setState({ paymentError: 'Erro no servidor.' })
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {

    this._isMounted = true

    this.createCards()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Recalcula os valores de parcelas caso ocorra a remoção de um livro/voucher
    if (this.props.orderTotal !== nextProps.orderTotal && nextProps.orderTotal > 0) {
      this.props.getInstallments(nextProps.orderTotal + nextProps.freightAmount, nextProps.schoolSapCode)
    }
  }

  checkAlreadyPurchase = async () => {
    const { orders } = await getOrdersByUser({ id: this.props.user.id })
    const voucher_id = this.props.activeVoucher.toUpperCase()
    const order = _.first(
      orders.filter(order => {
        if (['PG', 'AP', 'BAP', 'PAV'].includes(order.status)) {
          const vouchers = _.values(order.vouchers).map(voucher => {
            return _.keys(voucher)[0].toUpperCase()
          })
          return vouchers.includes(voucher_id)
        }
        return false
      }),
    )
    if (order) {
      this.purchasedOrder = order.order_id
      this.setState({ showDuplicatedOrderModal: true })
    } else {
      this._handleOnSubmit()
    }
  }

  render() {

    const formControl = {
      setState: this.setState.bind(this),
      checkAlreadyPurchase: this.checkAlreadyPurchase,
      _generateInstalmentOptions: this._generateInstalmentOptions,
      state: this.state,
      creditCard: this.props.creditCard
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="mx-auto">
          {this.state.isDone && <Navigate to="/steps/concluido" />}
          <div className="card-register">
            <h3 className="card-register__title">Dados do seu cartão</h3>
            {this.state.cards.map((card, index) => {
              return (
                <div key={index}>
                  <div className={'card-register__content'}>
                    <div className="card-register__form">
                      {this.state.cards.length > 1 && <h2 style={{ marginRight: '1.2em', fontSize: '1.3em' }}>Cartão {index + 1}</h2>}
                      <CreditCardForm key={index} index={index} card={card} control={formControl} />
                    </div>
                    <div>
                      <div className={'card'}>
                        <Cards
                          number={card.card_number}
                          name={card.name}
                          expiry={card.card_expire}
                          cvc={card.cvv}
                          callback={(card, isValid) => this.setState({ ...card, isValid })}
                          focused={this.state.focused}
                        />
                        <div className="field card-register__footer">
                          <span className="field__error">{this.state.paymentError}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                  {this.state.cards.length > 0 && index + 1 !== this.state.cards.length && <hr />}
                </div>
              )
            })}

          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              id="payment-finish-button"
              className="button__normal card-register__button"
              type="submit"
              onClick={this.checkAlreadyPurchase}
              disabled={this.state.processing}
            >
              Finalizar
            </button>
          </div>
        </div>
        {this.state.paymentErrorCode > 0 ? (this.state.is_two_cards)? <CreditCardReasonsTwocards /> : <CreditCardReasons /> : null}
        <DuplicatedOrderModal
          show={this.state.showDuplicatedOrderModal}
          onClose={() => {
            this.setState({ showDuplicatedOrderModal: false })
            this._handleOnSubmit()
          }}
          purchasedOrder={this.purchasedOrder}
        />
        <DuplicatedOrderAlertModal
          show={this.state.showDuplicatedOrderAlertModal}
          onClose={() => {
            this.setState({ showDuplicatedOrderAlertModal: false })
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.sessionReducer.user,
    creditCard: state.creditCard,
    freightAmount: state.freight.calculated_freight,
    checkout: filterActiveProducts(state),
    orderTotal: checkoutTotalDiscounted(state),
    withoutDiscount: checkoutTotal(state),
    schoolSapCode: _.isEmpty(state.checkout) ? null : state.checkout[0].school_sap_code,
    orders: state.order.orders,
    activeVoucher: state.activeVoucher.voucher,
  }
}

const mapDispatchToProps = dispatch => ({
  requestOrder: data => dispatch(requestOrder(data)),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  getInstallments: (order_total, school_sap_code) => dispatch(requestInstallments(order_total, school_sap_code)),
  clear: () => dispatch(clear()),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  requestOrder: cards => {

    dispatchProps.openLoader()

    const data = {
      voucher_data: [...stateProps.checkout],
      user_data: {},
      is_two_cards: cards.length > 1,
      card_data: cards.map(cardData => ({
        name: cardData.name,
        card_expire: cardData.card_expire,
        card_number: cardData.card_number,
        issuer: cardData.issuer,
        num_parcela: cardData.num_parcela,
        cvv: cardData.cvv,
        card_len: cardData.card_number.length,
        status_cvv: 1,
        tipo_cred: cardData.num_parcela > 1 ? 2 : 0,
        total: cardData.total,
        products: cardData.products,

      }
      )),
      type: 'credit_card',
      order_id: cards[0].order_id,
      order_total: stateProps.orderTotal.toFixed(2),
      order_without_discount: stateProps.withoutDiscount.toFixed(2),
    }

    return dispatchProps.requestOrder(data)
  },
  getInstallments: (order_total, school_sap_code) =>
    dispatchProps.getInstallments(order_total.toFixed(2), school_sap_code),
  clear: dispatchProps.clear,
  closeLoader: dispatchProps.closeLoader,
  creditCard: stateProps.creditCard,
  orderTotal: stateProps.orderTotal,
  schoolSapCode: stateProps.schoolSapCode,
  freightAmount: stateProps.freightAmount,
  activeVoucher: stateProps.activeVoucher,
  orders: stateProps.orders,
  user: stateProps.user,
  componentProps: ownProps,
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreditCard)