import * as React from "react";
export const OrderDeliveredIcon = ({props, active = '#309740'}) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={15}
      fill="white"
      stroke={active}
      strokeWidth={2}
    />
    <path
      d="M18.292 15.0833L15.4587 17.9167L14.042 16.5M16.167 22.875C12.6462 22.875 9.79199 20.0208 9.79199 16.5C9.79199 12.9792 12.6462 10.125 16.167 10.125C19.6878 10.125 22.542 12.9792 22.542 16.5C22.542 20.0208 19.6878 22.875 16.167 22.875Z"
      stroke={active}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
