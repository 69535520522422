import * as React from "react";
export const PaymentConfirmedIcon = ({props, active}) => (
  <svg
    width={33}
    height={32}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={16.5996}
      cy={16}
      r={15}
      fill="white"
      stroke={active}
      strokeWidth={2}
    />
    <g clipPath="url(#clip0_505_1595)">
      <path
        d="M24.086 20.6924C24.0094 20.9712 23.8793 21.22 23.6001 21.3363C23.4444 21.4011 23.2657 21.4396 23.0968 21.4401C21.2763 21.4474 19.4555 21.4445 17.635 21.4445C15.1656 21.4445 12.6959 21.4445 10.2266 21.4445C9.56811 21.4445 9.21094 21.0913 9.21094 20.4396C9.21094 17.7596 9.21094 15.0796 9.21094 12.3994C9.21094 11.7482 9.56811 11.395 10.2266 11.395C14.478 11.3948 18.7292 11.4003 22.9807 11.3887C23.5847 11.3869 23.9655 11.6392 24.086 12.1468V20.6921V20.6924ZM9.80477 15.5355C9.80477 15.5994 9.80477 15.6466 9.80477 15.6937C9.80477 17.2765 9.80477 18.8597 9.80477 20.4425C9.80477 20.7583 9.90317 20.8552 10.2228 20.8552C14.508 20.8552 18.7933 20.8552 23.0782 20.8552C23.3891 20.8552 23.4916 20.756 23.4916 20.4523C23.4921 18.8646 23.4919 17.2768 23.4916 15.6891C23.4916 15.6385 23.4857 15.5881 23.4825 15.5355H9.80477ZM9.81438 13.7551H23.4825C23.486 13.7091 23.4916 13.6669 23.4916 13.6249C23.4921 13.2064 23.4933 12.7878 23.4916 12.3693C23.4904 12.0924 23.3827 11.9843 23.1044 11.9843C18.8008 11.9837 14.497 11.9837 10.1934 11.9843C9.91423 11.9843 9.80652 12.0919 9.80536 12.3681C9.80332 12.7867 9.80448 13.2052 9.80536 13.6238C9.80536 13.666 9.81089 13.708 9.81438 13.7554V13.7551Z"
        fill={active}
        stroke={active}
        strokeWidth={0.7}
      />
      <path
        d="M14.5726 17.3093C15.4386 17.3093 16.3043 17.3087 17.1703 17.3096C17.3785 17.3096 17.4885 17.3822 17.5249 17.5352C17.5642 17.7012 17.4623 17.8595 17.2932 17.8875C17.2364 17.8971 17.1776 17.895 17.1197 17.895C15.417 17.8953 13.7141 17.8953 12.0115 17.895C11.9536 17.895 11.8948 17.8968 11.8377 17.8875C11.6983 17.865 11.5955 17.7432 11.5938 17.6055C11.5917 17.4626 11.6983 17.3371 11.845 17.316C11.9021 17.3079 11.9609 17.309 12.0188 17.3087C12.8702 17.3084 13.7214 17.3087 14.5729 17.3087L14.5726 17.3093Z"
        fill={active}
        stroke={active}
        strokeWidth={0.7}
      />
      <path
        d="M13.3806 18.4912C13.8644 18.4912 14.3482 18.4883 14.8317 18.4926C15.0713 18.4949 15.2134 18.6809 15.138 18.8828C15.0885 19.0156 14.9831 19.0752 14.8446 19.0766C14.6414 19.0787 14.4382 19.0775 14.235 19.0775C13.4901 19.0775 12.7452 19.0775 12.0003 19.0775C11.9473 19.0775 11.8931 19.079 11.841 19.0703C11.6943 19.0457 11.5895 18.9178 11.5939 18.7761C11.5982 18.6323 11.7065 18.5062 11.857 18.499C12.0887 18.4877 12.321 18.492 12.5533 18.4915C12.829 18.4906 13.1047 18.4915 13.3806 18.4915V18.4912Z"
        fill={active}
        stroke={active}
        strokeWidth={0.7}
      />
    </g>
    <defs>
      <clipPath id="clip0_505_1595">
        <rect
          width={17}
          height={17}
          fill="white"
          transform="translate(8.2666 8)"
        />
      </clipPath>
    </defs>
  </svg>
);
