import React, { useState } from 'react'

import { PAYMENT_PIX } from '.'
import barCode from './images/001-bar-code@2x.png'
import card from './images/002-credit-card@2x.png'
import boleto_hibrido from './images/boleto-hibrido.png'
import pixLogoImage from './images/pix.png'
import twoCardsImage from './images/two-credit-cards.png'

const PAYMENT_BILLET = 'boleto'
const PAYMENT_CARD = 'card'

const Panel = ({
  active,
  children,
  setActive,
  hasBillet,
  hasCard,
  hasTwoCards,
  thisParent,
  hybridBilletActivation,
  isPixEnabled,
  payWithTwoCards
}) => {

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="payment">
      <h3 className="payment__title">Pagamento</h3>
      <h4 className="payment__subtitle">Escolha a forma de pagamento</h4>
      {hasBillet === 1 && (
        <button
          className={`payment__button${active === PAYMENT_BILLET ? '--active' : ''} test-buttonBillet`}
          onClick={() => {
            setActive(PAYMENT_BILLET)
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'compra',
              eventAction: 'click',
              eventLabel: 'boleto_5',
            })

            thisParent.setState({ acceptDeliveryTerms: false })
          }}
        >
          <span className="payment__button-text">{hybridBilletActivation == 1 ? 'Boleto/PIX' : 'Boleto'}</span>
          <img
            className="payment__button-icon"
            src={hybridBilletActivation == 1 ? boleto_hibrido : barCode}
            alt={hybridBilletActivation == 1 ? 'Boleto/PIX' : 'Boleto'}
          />
        </button>
      )}

      {hasCard === 1 && (
        <button
          id="payment-credid-card-button"
          className={`payment__button${active === PAYMENT_CARD ? '--active' : ''} test-buttonCard`}
          onClick={() => {
            setActive(PAYMENT_CARD)
            thisParent.setState({ acceptDeliveryTerms: false })
          }}
        >
          <span className="payment__button-text">Cartão de crédito</span>
          <img className="payment__button-icon" src={card} alt="Cartão de crédito" />
        </button>
      )}

      {hasTwoCards === 1 && (
        <span
          onMouseEnter={() => {
            setIsHovered(true)
          }}
          onMouseLeave={() => setIsHovered(false)}
        >
          <button
            disabled={!payWithTwoCards}
            id="payment-credid-card-button"
            className={`payment__button`}
            onClick={() => {
              setActive('two-credit-cards')
              thisParent.setState({ acceptDeliveryTerms: false, acceptRulesPayTwoCreditCard:false })
            }}
          >
            <span className="payment__button-text">Dois Cartões de Crédito </span>
            <img className="payment__button-icon" src={twoCardsImage} alt="Dois Cartões de crédito" />
          </button>
        </span>
      )}

      {isPixEnabled && (
        <button
          className={`payment__button${active === PAYMENT_PIX ? '--active' : ''}`}
          onClick={() => {
            setActive(PAYMENT_PIX)
          }}
        >
          <span className="payment__button-text">PIX</span>
          <img className="payment__button-icon" src={pixLogoImage} alt="PIX" />
        </button>
      )}

      {children}

     {!payWithTwoCards && <div style={{
        padding: '10px',
        background: '#ffe3af9e',
        maxWidth: '550px',
        width: '100%',
        margin: 'auto',
        border: 'solid 1px orange',
        display: isHovered ? 'block' : 'none'
      }} className="alert-message">
        <span className="fa fa-warning"></span>
        Conforme notificado anteriormente, para a seleção de dois cartões, é
        necessário ter apenas um voucher (aluno) no carrinho. Exclua as opções excedentes e
        realize compras separadas para aplicar o método de pagamento em dois cartões.
      </div>} 
    </div>
  )
}

export default Panel

