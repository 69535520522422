import React, { Component } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Admin from '@containers/Admin'
import Faq from '@containers/Faq'
import Footer from '../Footer'
import Header from '../Header'
import Hero from '@components/Hero'
import Home from '@containers/Home'
import Loader from '@shared/Loader.js'
import Modal from '@shared/Modal.js'
import PrivateRoute from '@shared/PrivateRoute.js'
import Steps from '@containers/Steps'
import _ from 'lodash'
import { connect } from 'react-redux'
import { fetchActiveNotification } from '@actions/activeNotification'
import { withRouter } from '../../utils/withRouter'

import Summary from '@containers/Summary'
import Appearance from '@containers/Appearance'
import Authorization from '@shared/Authorization'
import Contract from '@containers/Contract'
import EditDiscount from '@components/EditDiscount'
import NotificationForm from '@containers/Notification/Form'
import NotificationList from '@containers/Notification'
import Order from '@components/Order'
import NotFound from '../../components/NotFound'
import OrderDetail from '@components/OrderDetail'
import Report from '@containers/Report'
import School from '@containers/School'
import SchoolPaymentOptions from '@containers/SchoolPaymentOptions'
import SpecialDiscountForm from '@containers/SpecialDiscount/Form'
import SpecialDiscountList from '@containers/SpecialDiscount'
import VouchersInactivation from '@containers/VouchersInactivation'
import Ticket from '@containers/Ticket'
import Variables from '@containers/Variables'
import Voucher from '@containers/Voucher'
import EditVoucher from '@components/EditVoucher'
import EditUser from '@components/EditUser'
import ConfirmProductsContainer from '@containers/ConfirmProductsContainer'
import DoneStep from '@components/DoneStep'
import Payment from '@components/Payment'
import Identification from '@components/Identification'
import SelectProductsContainer from '@containers/SelectProductsContainer'
import UserUpdateForm from '@components/UserUpdateForm'
import TransferList from '../TransferList'
import { TransferListProvider } from '../../hooks/useTransferList'

class App extends Component {
  componentDidMount() {
    const { pathname } = this.props.location
    window.onpopstate = e => {
      if (pathname.includes('/steps')) {
        window.localStorage.removeItem('reduxPersist:checkout')
        window.localStorage.removeItem('reduxPersist:preVoucher')
        window.location.href = '/'
      }
    }
    const shouldRedirectToHome =
      pathname.includes('/steps') && !pathname.includes('/steps/concluido') && _.isEmpty(this.props.checkout)
    if (shouldRedirectToHome) {
      if (_.isEmpty(this.props.checkout)) {
        window.localStorage.removeItem('reduxPersist:checkout')
        window.localStorage.removeItem('reduxPersist:preVoucher')
        window.location.href = '/'
      }
    }

    this.props.fetchActiveNotification()
  }

  render() {
    let { sessionReducer, location } = this.props

    if (
      sessionReducer.checked &&
      sessionReducer.user &&
      sessionReducer.user.roles &&
      sessionReducer.user.roles.length &&
      location.pathname.replace(/\//gi, '') === 'admin'
    ) {
      const redirectTarget =
        sessionReducer.user.roles[0].id >= 2
          ? '/admin/dashboard'
          : sessionReducer.user.roles[0].name === 'Comprador'
            ? '/admin/pedidos'
            : null

      if (redirectTarget) return <Navigate to={redirectTarget} replace />
    }

    return (
      <div className="app">
        <Loader />
        <Modal />
        <div className="app__content">
          <Header />
          <Routes>
            <Route
              exact
              path="/transfer-list"
              element={<TransferList />}
            />
            <Route
              exact
              path="/"
              element={
                <>
                  <Hero />
                  <Home />
                </>
              }
            />
            <Route
              exact
              path="desconto-professor"
              element={
                <>
                  <Hero specialDiscount />
                  <Home />
                </>
              }
            />
            <Route
              path="faq"
              element={
                <>
                  <Hero />
                  <Faq />
                </>
              }
            />
            <Route path="steps" element={<Steps />}>
              <Route path="selecionar-livros" element={<SelectProductsContainer />} />
              <Route path="confirmacao" element={<ConfirmProductsContainer />} />
              <Route path="identificacao" element={<Identification />} />
              <Route path="atualizacao" element={<UserUpdateForm />} />
              <Route path="pagamento" element={<Payment />} />
              <Route path="concluido" element={<DoneStep />} />
            </Route>
            {sessionReducer.checked && (
              <Route path="admin" element={<PrivateRoute component={Admin} />}>
                <Route
                  exact
                  path="edit/:id/*"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_users']}>
                          <EditUser edit />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  exact
                  path="novo/*"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_users']}>
                          <EditUser edit={false} />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="dashboard"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_financial_dashboard']}>
                          <Summary />
                        </Authorization>
                      )}
                    />
                  }
                  exact
                />

                <Route path="pedidos" element={<PrivateRoute component={Order} exact />} />
                <Route
                  path="pedidos/:order_id"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['access_order_report', 'access_order']}>
                          <OrderDetail {...params} />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="escolas/:school_id"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['access_school_report']}>
                          <School {...params} />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="escolas/:school_id/voucher"
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['access_school_report']}>
                          <Voucher {...params} />
                        </Authorization>
                      )}
                    />
                  }
                >
                  <Route path="novo" exact element={<EditVoucher edit={false} />} />
                  <Route path="edit/:id/*" exact element={<EditVoucher edit={true} />} />
                </Route>
                <Route
                  path="atendimento"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['open_case']}>
                          <Ticket />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="aparencia"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['theme_aspect_change']}>
                          <Appearance />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="relatorios/pedidos"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_order_report']}>
                          <Report title="Relatório de pedidos" subTitle="Pedidos" url="orders/list" type="orders" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="relatorios/vouchers"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_voucher_report']}>
                          <Report
                            title="Relatório de vouchers"
                            subTitle="Vouchers"
                            url={`voucher/${this.props.sessionReducer.user.school_id}/list`}
                            type="vouchers"
                          />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="relatorios/escolas"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_school_report']}>
                          <Report
                            title="Relatório de escolas"
                            subTitle="Escolas"
                            type="schools"
                            url="contracts/schools/list"
                          />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="relatorios/log-de-erros"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_log_error']}>
                          <Report
                            title="Log de erros"
                            subTitle="Logs"
                            type="sap_order_log"
                            url="orders/sap/report/list"
                          />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="sistema/fila"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_log_error']}>
                          <Report title="Fila" subTitle="Itens da fila" type="enqueue" url="enqueue/report/list" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="sistema/logs"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_log_error']}>
                          <Report title="Logs" subTitle="Logs do sistema" type="log" url="log/report/list" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="relatorios/usuarios"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_user_report']}>
                          <Report
                            title="Relatório de usuários"
                            subTitle="Usuários"
                            type="users"
                            url="users/report/list"
                          />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="contratos"
                  element={
                    <PrivateRoute
                      component={() => (
                        <Authorization permissions={['access_contract']}>
                          <Contract />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="escolas/desconto/:school_id"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['access_school_report']}>
                          <EditDiscount {...params} />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="special-discounts"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['access_special_discounts']}>
                          <SpecialDiscountList {...params} />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="special-discounts/novo"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['manage_special_discounts']}>
                          <SpecialDiscountForm {...params} title="Novo Desconto Professor" editMode="CREATE" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="special-discounts/:id"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['manage_special_discounts']}>
                          <SpecialDiscountForm {...params} title="Editar Desconto Professor" editMode="UPDATE" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="sistema/variables"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['manage_system_variables']}>
                          <Variables {...params} title="Variables" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="notifications"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['access_notifications']}>
                          <NotificationList {...params} />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="notifications/novo"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['manage_notifications']}>
                          <NotificationForm {...params} title="Nova notificação" editMode="CREATE" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="notifications/:id"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['manage_notifications']}>
                          <NotificationForm {...params} title="Editar Notificação" editMode="UPDATE" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="school-payments"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['access_school_payment_options', 'manage_school_payment_options']}>
                          <SchoolPaymentOptions {...params} title="Meios de Pagamento" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="sistema/inativacao-vouchers"
                  exact
                  element={
                    <PrivateRoute
                      component={params => (
                        <Authorization permissions={['manage_vouchers_inactivation']}>
                          <VouchersInactivation {...params} title="Inativação automática de vouchers e produtos" />
                        </Authorization>
                      )}
                    />
                  }
                />
                <Route
                  path="*"
                  exact
                  element={
                    <PrivateRoute
                      component={() => {
                        return <NotFound routeToRedirect={'/admin/'} />
                      }}
                    />
                  }
                />
              </Route>
            )}
          </Routes>

          <Routes>
            <Route exact path="/" element={<Footer />} />
            <Route path="admin/*" element={<Footer />} />
            <Route path="faq" element={<Footer />} />
            <Route path="steps/concluido" element={null} />
          </Routes>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checkout: state.checkout,
  sessionReducer: state.sessionReducer,
})

const mapDispatchToProps = dispatch => ({
  fetchActiveNotification: () => dispatch(fetchActiveNotification()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))

