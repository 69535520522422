import React, { Component } from 'react'
import { activateStudentForm, activateVoucherForm } from '@actions/voucherForm'
import _ from 'lodash'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import {
  checkoutTotal,
  checkoutTotalDiscounted,
  getActiveStudent,
  removeStudent,
  resetWarning,
} from '@actions/checkout'
import { closeModal, openModal } from '@actions/modal.js'
import ConfirmDialog from '@shared/ConfirmDialog'
import Tooltip from '@shared/Tooltip.js'
import add from './images/add.png'
import bus from './images/bus.png' 
import info from './images/info.png'
import { clearVoucher } from '@actions/preVoucher'
import edit from './images/edit.png'
import { listVariables } from '@services/sm-api'
import remove from './images/delete.png'
import { updateActiveVoucher } from '@actions/activeVoucher'
import wallet from './images/wallet.png'
import { EDUCA_ID } from '../../utils/product/businessLine'
import { withRouter } from '../../utils/withRouter'

class StudentCheckout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      checkouts: [],
      items: null,
      showSavingUp: true,
      calculated_freight: null,
      openModalFreight: false,
      openModalFreightError: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({}, () => {
      if (window.innerWidth < 768) {
        this.setState({ items: null })
      } else {
        this.setState({ items: this._getCheckoutList() })
      }

      let atualizacao = nextProps.location.pathname === '/steps/atualizacao'
      let identificacao = nextProps.location.pathname === '/steps/identificacao'

      if (atualizacao || identificacao) {
        if (nextProps.calculated_freight !== null) {
          if (nextProps.calculated_freight !== this.state.calculated_freight) {
            if (atualizacao) {
              if (nextProps.freight_redux_requests > 1) {
                this.setState({
                  openModalFreight: true,
                  calculated_freight: nextProps.calculated_freight,
                })
              }
            } else {
              this.setState({
                openModalFreight: true,
                calculated_freight: nextProps.calculated_freight,
              })
            }
          }
        } else {
          this.setState({
            calculated_freight: null,
          })
        }

        if (nextProps.freight_error !== null) {
          if (atualizacao) {
            if (nextProps.freight_redux_requests > 1) {
              this.setState({
                openModalFreightError: true,
                calculated_freight: null,
              })
            }
          } else {
            this.setState({
              openModalFreightError: true,
              calculated_freight: null,
            })
          }
        }
      }
    })
  }

  componentDidMount() {
    listVariables().then(data => {
      const extraDiscountValue = _.get(_.find(data, { key: 'EXTRA_DISCOUNT_VALUE' }), 'value', false)
      const extraDiscountMessage = _.get(_.find(data, { key: 'EXTRA_DISCOUNT_MSG' }), 'value', false)

      this.setState({}, () => {
        if (window.innerWidth < 768) {
          this.setState({
            items: null,
            extraDiscountValue,
            extraDiscountMessage,
          })
        } else {
          this.setState({
            items: this._getCheckoutList(),
            extraDiscountValue,
            extraDiscountMessage,
          })
        }
      })

      if (window.innerWidth < 768) {
        this.setState({
          items: null,
          extraDiscountValue,
          extraDiscountMessage,
        })
        setTimeout(() => {
          this.setState({
            showSavingUp: false,
            extraDiscountValue,
            extraDiscountMessage,
          })
        }, 8000)
      }
    })
  }

  _toggleCheckout() {
    if (window.innerWidth < 768) {
      this.setState({
        open: !this.state.open,
        items: this.state.items === null ? this._getCheckoutList() : null,
      })
    }
  }

  _handleUpdateVoucher(voucher, activeVoucher) {
    if (this.props.location.pathname !== '/steps/selecionar-livros') {
      this.props.navigate('/steps/selecionar-livros')
    }
    this.props.updateVoucher(voucher)
    this.props.resetWarning(activeVoucher)
    this._toggleCheckout()
  }

  _hasExtraDiscount() {
    return !_.isEmpty(this.state.extraDiscountValue) && !_.isEmpty(this.state.extraDiscountMessage)
  }

  _getExtraDiscountValue(total) {
    return total * (this.state.extraDiscountValue / 100)
  }

  _getAppliedExtraDiscount(total) {
    return total - this._getExtraDiscountValue(total)
  }

  _getCheckoutList() {
    let { showAddNew, checkouts, removeCheckout, activeVoucher, openModal, closeModal, showVoucherForm } = this.props
    return (
      <div className="student-checkout__mobile-view">
        <ul className="student-checkout__list">
          {checkouts.map((checkout, index, arr) => {
            let active = this.props.activeVoucher.voucher === checkout.voucher ? '--selected' : ''
            return (
              <li key={index} className={`student-checkout__item${active}`}>
                <div className="student-checkout__position">{index + 1}</div>
                <div className="student-checkout__info">
                  <span className="student-checkout__info__name">{checkout.name}</span>
                  <span className="student-checkout__info__voucher">Voucher: {checkout.voucher}</span>
                  <span className="student-checkout__info__amount">
                    {checkout.products.filter(product => product.active).length} itens
                  </span>
                </div>
                <div className="student-checkout__icons">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '5px',
                    }}
                  >
                    <div
                      onClick={() => {
                        window.ga('send', {
                          hitType: 'event',
                          eventCategory: 'edit_aluno',
                          eventAction: 'click',
                          eventLabel: 'box_lateral',
                        })
                        this._toggleCheckout()
                        this.props.showStudentForm(checkout, index)
                      }}
                      style={{
                        color: 'red',
                        cursor: 'pointer',
                      }}
                    >
                      Editar nome do aluno
                    </div>
                    <div
                      className="student-checkout__icons__edit"
                      onClick={this._handleUpdateVoucher.bind(this, checkout, activeVoucher)}
                      style={{
                        marginLeft: '10px',
                        marginTop: '7px',
                      }}
                    >
                      <img src={edit} alt="Editar" />
                    </div>
                  </div>
                  {arr.length > 1 ? (
                    <div
                      className="student-checkout__icons__delete"
                      onClick={() =>
                        openModal(
                          <ConfirmDialog
                            text="Você tem certeza que deseja remover este aluno?"
                            ok={() => removeCheckout(index, index === 0 ? arr[1] : arr[0])}
                            cancel={() => closeModal()}
                          />,
                        )
                      }
                    >
                      <img src={remove} alt="Remover" />
                    </div>
                  ) : null}
                  {_.isInteger(checkout.special_discount_id) ? (
                    <React.Fragment>
                      <span className="student-checkout__total__from item">
                        de {checkout.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </span>
                      <span className="student-checkout__total__to item">
                        por R${' '}
                        {checkout.total_discounted.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </span>
                    </React.Fragment>
                  ) : (
                    <span className="student-checkout__icons__price">
                      {checkout.total_discounted.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </span>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
        {showAddNew && (
          <div
            className="student-checkout__new"
            onClick={() => {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'add_aluno',
                eventAction: 'click',
                eventLabel: 'box_lateral',
              })

              this._toggleCheckout()
              showVoucherForm()
            }}
          >
            <img src={add} alt="Novo aluno" />
            <div>Novo aluno</div>
          </div>
        )}
      </div>
    )
  }

  _hasDiscount() {
    return (
      this._hasExtraDiscount() ||
      (checkoutTotal && checkoutTotalDiscounted && checkoutTotal !== checkoutTotalDiscounted)
    )
  }

  _modalFreight() {
    return (
      <Modal show={this.state.openModalFreight}>
        <Modal.Body>
          <div className="modalHeader _modalFreight">
            <span className="icon-alert"></span>
            <h1>Frete atualizado!</h1>
          </div>

          <div className="modalContent">
            <p className="text-center">O custo do frete foi atualizado!</p>
          </div>

          <div className="modalFooter">
            <button
              className="modal-close"
              onClick={() => {
                this.setState({
                  openModalFreight: false,
                })
              }}
            >
              Entendi
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  _modalFreightError() {
    return (
      <Modal show={this.state.openModalFreightError}>
        <Modal.Body>
          <div className="modalHeader _modalFreight">
            <span className="icon-alert"></span>
            <h1>Atenção!</h1>
          </div>

          <div className="modalContent">
            <p className="text-center">Infelizmente não podemos entregar no endereço de entrega digitado!</p>
            <p className="text-center">Por favor digite outro endereço para a entrega.</p>
          </div>

          <div className="modalFooter">
            <button
              className="modal-close"
              onClick={() => {
                this.setState({
                  openModalFreightError: false,
                })
              }}
            >
              Entendi
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    const { isSpecialDiscount, checkouts, checkoutTotal, checkoutTotalDiscounted, activeStudent } = this.props

    let toggleContainer = this.state.open ? 'open' : ''
    let scroll = checkouts.length > 3 && this.state.items !== null ? 'scroll' : ''
    let showSavingUp = !this.state.showSavingUp ? 'disabled' : ''

    const first = checkouts ? _.first(checkouts) : null

    let school_is_b2c = first ? Boolean(first.school_is_b2c) : false
    const totalDiscount = checkoutTotal - checkoutTotalDiscounted + this._getExtraDiscountValue(checkoutTotal)
    const showDiscount = totalDiscount > 0
    return (
      <div className={`student-checkout ${scroll}`}>
        <Tooltip className="tooltip" hidesAfter="10000" show={window.innerWidth < 768 && activeStudent.show_warning}>
          <span className="tooltip__body-content">Seu desconto reduz ao excluir um item</span>
        </Tooltip>
        {isSpecialDiscount && <div className="special-discount-header">DESCONTO PROFESSOR</div>}
        <div className={`student-checkout__container ${toggleContainer}`}>
          <ReactCSSTransitionGroup
            component="div"
            transitionName="toggle"
            transitionEnterTimeout={400}
            transitionLeaveTimeout={300}
          >
            {this.state.items}
          </ReactCSSTransitionGroup>
          <div
            className="student-checkout__total"
            ref={divTotal => (this.divTotal = divTotal)}
            onClick={() => {
              this._toggleCheckout()
            }}
          >
            <div className="student-checkout__editing">
              <span className="student-checkout__editing__count">{checkouts.length}</span>
              <span className="student-checkout__editing__name">{activeStudent.name}</span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span className="student-checkout__total__label">
                {this._hasExtraDiscount() || school_is_b2c ? 'Subtotal' : 'Total'}
              </span>
              <div
                className="student-checkout__total__price"
                style={{
                  marginLeft: 'auto',
                }}
              >
                {checkoutTotal && checkoutTotalDiscounted && checkoutTotal !== checkoutTotalDiscounted ? (
                  <React.Fragment>
                    <div>
                      {showDiscount && (
                        <span className="student-checkout__total__from">
                          de {checkoutTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                      )}
                      <span className="student-checkout__total__to">
                        por {checkoutTotalDiscounted.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </span>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>
                      <div className="student-checkout__total__price single" style={{ marginRight: '0' }}>
                        <span className="student-checkout__total__to">
                          {checkoutTotalDiscounted.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            {school_is_b2c && this.props.calculated_freight > 0 && (
              <div style={{
                padding: '1rem 0',
                borderBottom: '2px solid #bf4c4c',
                borderTop: '2px solid #bf4c4c',
                margin: '1rem 0'
              }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <span className="student-checkout__total__label">Custo de frete</span>
                  <div
                    className="student-checkout__total__price"
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <div>
                      <div
                        className="student-checkout__total__price single"
                        style={{
                          whiteSpace: 'nowrap',
                          marginRight: '0',
                        }}
                      >
                        <span className="student-checkout__total__to">
                          {this.props.calculated_freight.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  checkouts.length > 1 && ( 
                    <p style={{
                      padding: '1rem',
                      margin: '0 1rem',
                      backgroundColor: '#ff8383',
                      borderRadius: '8px',
                      color: '#fff',
                      fontSize: '14px'
                    }}><img src={info}/> Ao realizar a compra para dois ou mais alunos no mesmo pedido, o frete será calculado com base no valor total dos pedidos.</p>
                  )
                }
              </div>
            )}
            {this._hasExtraDiscount() && (
              <React.Fragment>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <span className="student-checkout__total__label">{this.state.extraDiscountMessage}</span>
                  <div
                    className="student-checkout__total__price"
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <div>
                      <div
                        className="student-checkout__total__price single"
                        style={{
                          whiteSpace: 'nowrap',
                          marginRight: '0',
                        }}
                      >
                        <span className="student-checkout__total__to">
                          R${' '}
                          {this._getExtraDiscountValue(checkoutTotal).toLocaleString('pt-br', {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

            {((school_is_b2c && this.props.calculated_freight > 0) || this._hasExtraDiscount()) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <span className="student-checkout__total__label">Total</span>
                <div
                  className="student-checkout__total__price"
                  style={{
                    marginLeft: 'auto',
                  }}
                >
                  <div>
                    <div
                      className="student-checkout__total__price single"
                      style={{
                        whiteSpace: 'nowrap',
                        marginRight: '0',
                      }}
                    >
                      <span className="student-checkout__total__to">
                        {(
                          this.props.calculated_freight +
                          (checkoutTotalDiscounted - this._getExtraDiscountValue(checkoutTotal))
                        ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {!school_is_b2c || (this.props.calculated_freight !== null && this.props.calculated_freight <= 0) ? (
          <div className={`student-checkout__shipping ${showSavingUp}`}>
            <span className="student-checkout__shipping__label">Aqui você tem frete grátis. Aproveite!</span>
            <img src={bus} alt="Carteira" />
          </div>
        ) : null}
        {showDiscount && (
          <div className={`student-checkout__savingup ${showSavingUp}`}>
            <span className="student-checkout__savingup__label">
              Você está economizando{' '}
              <span className="student-checkout__savingup__price">
                {totalDiscount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </span>
            </span>
            <img src={wallet} alt="Carteira" />
          </div>
        )}
        {school_is_b2c && this._modalFreight()}
        {school_is_b2c && this._modalFreightError()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const specialDiscount = state.specialDiscount
  const checkouts = state.checkout

  const has_special_discount = !_.isEmpty(specialDiscount) && !_.isEmpty(specialDiscount.special_discounts)

  const available_special_discount_vouchers = has_special_discount
    ? specialDiscount.special_discounts.filter(
        discount =>
          !_.find(checkouts, {
            special_discount_id: Number(discount.special_discount_id),
          }),
      )
    : []

  const showAddNew = !has_special_discount || !_.isEmpty(available_special_discount_vouchers)

  return {
    isSpecialDiscount: has_special_discount,
    showAddNew,
    checkouts,
    activeVoucher: state.activeVoucher,
    activeStudent: getActiveStudent(state),
    checkoutTotal: checkoutTotal(state),
    checkoutTotalDiscounted: checkoutTotalDiscounted(state),
    calculated_freight: state.freight.calculated_freight,
    freight_redux_requests: state.freight.freight_redux_requests,
    freight_error: state.freight.error,
  }
}

const mapDispatchToProps = dispatch => ({
  updateVoucher: voucher => {
    dispatch(activateVoucherForm(false))
    dispatch(updateActiveVoucher(voucher.voucher, voucher.name))
  },
  resetWarning: activeVoucher => dispatch(resetWarning(activeVoucher)),
  openModal: component => dispatch(openModal(component)),
  closeModal: () => dispatch(closeModal()),
  removeCheckout: (index, voucher) => {
    dispatch(removeStudent(index))
    dispatch(closeModal())
    dispatch(updateActiveVoucher(voucher.voucher, voucher.name))
  },
  showVoucherForm: () => {
    dispatch(clearVoucher())
    dispatch(updateActiveVoucher(null))
    dispatch(activateVoucherForm(true))
  },
  showStudentForm: (voucher, index) => {
    dispatch(updateActiveVoucher(voucher.voucher, voucher.name))

    dispatch(activateStudentForm(true, index))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentCheckout))

