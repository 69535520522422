import React, { Component } from 'react'
import SummarySalesChart from '@components/SummarySalesChart'
import { SimpleCard } from '@components/AdminCard'

export class Default extends Component {
  render() {
    // let { retributive_amount, total_orders, total_students, total_products } = this.props.summary
    let {
      children,
      summary: {
        retributive_amount,
        total_products,
        total_amount,
        total_students,
        total_students_paid,
        amount_teachers,
        ...rest
      },
    } = this.props

    const data = [
      {
        name: 'Comprado',
        value: total_students_paid,
        fill: '#F5A8A8',
      },
      {
        name: 'Em aberto',
        value: total_students,
        fill: '#6AABD6',
      },
    ]

    return (
      <div className="summary">
        <div className="summary__header">
          <h3 className="summary__title">Pedido</h3>
        </div>
        <div>{children}</div>
        <SummarySalesChart data={data} />
        <div className="summary__cards">
          <SimpleCard
            className="summary__card"
            title="Valor total"
            value={total_amount}
            color="#8DC496"
            position="top"
          />
          <SimpleCard
            className="summary__card"
            title="Valor total venda professor(a)"
            value={amount_teachers}
            color="#6AABD6"
            position="top"
          />
          <SimpleCard className="summary__totalcard">
            <div className="summary__totalbooks">
              <div className="totalbooks__total">
                <span className="simplecard__title">Valor de repasse total</span>
                <span className="totalbooks__value">{retributive_amount}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse literatura</span>
                <span className="totalbooks__value">{rest.retributive_amount_lij}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse didático</span>
                <span className="totalbooks__value">{rest.retributive_amount_didact}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse digital</span>
                <span className="totalbooks__value">{rest.retributive_amount_dig}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse educamos</span>
                <span className="totalbooks__value">{rest.retributive_amount_edu}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse serviços</span>
                <span className="totalbooks__value">{rest.retributive_amount_ser}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse serviços</span>
                <span className="totalbooks__value">{rest.retributive_amount_ere}</span>
              </div>
            </div>
          </SimpleCard>
          <SimpleCard
            className="summary__card"
            title="Comprados"
            value={`${total_products} produtos`}
            color="#ECCC62"
            position="bottom"
          />
        </div>
      </div>
    )
  }
}

export class SM extends Component {
  render() {
    let {
      children,
      summary: {
        retributive_amount,
        total_amount,
        net_amount,
        total_students,
        total_students_paid,
        amount_teachers,
        ...rest
      },
    } = this.props

    const data = [
      {
        name: 'Comprado',
        value: total_students_paid,
        fill: '#F5A8A8',
      },
      {
        name: 'Em aberto',
        value: total_students,
        fill: '#6AABD6',
      },
    ]

    return (
      <div className="summary">
        <div className="summary__header">
          <h3 className="summary__title">Dashboard</h3>
        </div>
        {children}
        <SummarySalesChart data={data} />
        <div className="summary__cards">
          <SimpleCard
            className="summary__card"
            title="Valor total"
            value={total_amount}
            color="#8DC496"
            position="top"
          />
          <SimpleCard
            className="summary__card"
            title="Valor total venda professor(a)"
            value={amount_teachers}
            color="#6AABD6"
            position="top"
          />
          <SimpleCard className="summary__totalcard">
            <div className="summary__totalbooks">
              <div className="totalbooks__total">
                <span className="simplecard__title">Valor de repasse total</span>
                <span className="totalbooks__value">{retributive_amount}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse literatura</span>
                <span className="totalbooks__value">{rest.retributive_amount_lij}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse didático</span>
                <span className="totalbooks__value">{rest.retributive_amount_didact}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse digital</span>
                <span className="totalbooks__value">{rest.retributive_amount_dig}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse educamos</span>
                <span className="totalbooks__value">{rest.retributive_amount_edu}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse serviços</span>
                <span className="totalbooks__value">{rest.retributive_amount_ser}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse bilíngue</span>
                <span className="totalbooks__value">{rest.retributive_amount_bil}</span>
              </div>
              <div className="column">
                <span className="simplecard__title">Valor total do repasse ensino religioso</span>
                <span className="totalbooks__value">{rest.retributive_amount_ere}</span>
              </div>
            </div>
          </SimpleCard>
          <SimpleCard
            className="summary__card"
            title="Valor total líquido"
            value={`${net_amount}`}
            color="#ECCC62"
            position="top"
          />
        </div>
      </div>
    )
  }
}

