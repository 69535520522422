import { CNPJ, CPF } from 'cpf_cnpj'
import { Form, Radio, RadioGroup, Select, Text } from 'react-form'
import moment from 'moment'
import {
  InputCheckbox,
  InputText,
  InputPassword,
  regexEmail,
  SelectState,
  SelectSchoolsUser,
  regexPassword,
  regexZipcodeFull,
} from '@shared/CustomField'
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { closeLoader, openLoader } from '@actions/loader.js'
import { closeModal, openModal } from '@actions/modal.js'
import {
  getSchools,
  getUserByEmail,
  getUserData,
  requestDelete,
  requestEnable,
  requestRegister,
  requestUpdate,
} from '@actions/user'

import NormalizeBrazilianStates from '@shared/NormalizeBrazilianStates'

import Cep from 'cep-promise'
import ConfirmDialog from '@shared/ConfirmDialog'
import PermissionsChecker from '@shared/PermissionsChecker'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getSchoolList } from '@services/sm-auth'
import { userPermissions } from './permissions'
import { withRouter } from '../../utils/withRouter'

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingField: 'cpf',
      email: null,
      userAlreadyExists: {},
      userData: {},
      isBuyer: null,
      isSchool: null,
      isAdmin: null,
      isSchoolEditor: null,
      userPage: null,
      showForm: false,
      schools: [],
      deleted: false,
      address_zipcode: '',
      address_found: false,
      address_incomplete: false,
      unique_zipcode: false,
      ie_free: false,
      ieModalOpen: false,
      invalid_address_zipcode: false,
      disable_address_state: true,
    }

    /* eslint-disable */
    this.regexEmail =
      /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    /* eslint-enable */
    this.hideDefaultError = { style: { display: 'none' } }
    this._getAddress = this._getAddress.bind(this)
  }

  /**
   * Get full address by CEP number and fill the adresses fields
   **/
  _getAddress(event, setValue, setAllValues) {
    this.props.openLoader()
    const value = event.target.value
    let cep = value.replace('-', '')
    if (cep.length === 8) {
      Cep(cep)
        .then(data => {
          this.setState({
            address_found: true,
            address_incomplete: false,
          })
          setValue('address_number', '', true)
          setValue('address_complement', '', true)
          setValue('address_reference', '', true)
          setValue('address_street', data.street, true)
          setValue('address_city', data.city, true)
          setValue('address_neighborhood', data.neighborhood, true)
          if (data.state && NormalizeBrazilianStates(data.state)) {
            setValue('address_state', NormalizeBrazilianStates(data.state), true)
          } else {
            this.setState({ disable_address_state: false })
          }
          this.setState({
            invalid_address_zipcode: false,
          })
          if (this._isUniqueZipcode(data)) {
            this.setState({
              unique_zipcode: true,
            })
          } else {
            this.setState({
              unique_zipcode: false,
            })
          }
        })
        .catch(() => {
          ;[
            'address_number',
            'address_complement',
            'address_reference',
            'address_street',
            'address_city',
            'address_neighborhood',
            'address_state',
          ].forEach(field => {
            setValue(field, '', true)
          })

          this.props.openModal(
            <ConfirmDialog
              text="Dados não encontrados para o cep informado."
              okLabel="Confirmar"
              ok={() => {
                this.props.closeModal()
              }}
            />,
          )

          this.setState({
            address_found: false,
            address_incomplete: true,
            unique_zipcode: false,
            invalid_address_zipcode: true,
          })
        })
    }
    this.props.closeLoader()
  }

  _isUniqueZipcode(address) {
    if (address.street === 'undefined' || address.street.length === 0) {
      return true
    }
    if (address.neighborhood === 'undefined' || address.neighborhood.length === 0) {
      return true
    }
    return false
  }

  UNSAFE_componentWillMount() {
    if (this.props.edit) {
      let editingUserRequest = {
        id: this.props.params.id,
        token: this.props.user.token,
      }

      this.props
        .getUserData(editingUserRequest)
        .then(user => {
          let loggedUser = this.props.sessionReducer.user

          this.setState({ deleted: !!user.deleted })

          if (
            loggedUser.id === user.id ||
            (this.state.isSchool && this.state.isSchoolEditor && loggedUser.school_id === user.school_id) ||
            this.state.isAdmin
          ) {
            this.setState({ userPage: user, showForm: true })
            this._toggleTypeField(user.type)

            user.ie_free = 0

            if (user.ie === 'ISENTO') {
              this.setState({ ie_free: true })
              user.ie_free = 1
            }

            this._fillFields(user)
          } else {
            this.props.navigate(`/admin`)
          }
        })
        .catch(error => {
          console.log(error)
          this.props.navigate(`/admin`)
        })
    } else {
      this.setState({ showForm: true })
    }
  }

  componentDidMount() {
    let loggedUser = this.props.sessionReducer.user
    this.setState({
      isBuyer: loggedUser.roles.find(role => role.id === 1),
      isSchool: loggedUser.roles.find(role => role.id === 2),
      isAdmin: loggedUser.roles.find(role => role.id >= 3),
      isSchoolEditor: loggedUser.permissions.indexOf('access_users') !== -1,
    })

    getSchoolList().then(response => {
      let schools = response.map((school, index) => {
        school.label = school.fantasy_name
        school.value = school.id
        school.key = index
        delete school.id
        delete school.social_name
        return school
      })
      schools.unshift({
        label: 'Selecione',
        value: null,
      })
      this.setState({
        schools,
      })
    })
  }

  _toggleIE() {
    this.setState({ ie_free: !this.state.ie_free })
    if (this.state.ie_free) {
      this.refs.Form.setValue('ie', '')
    } else {
      this.refs.Form.setValue('ie', 'ISENTO')
    }
  }

  _fillFields(user) {
    user.email = this.state.email ? this.state.email : user.email
    if (typeof this.refs.Form !== 'undefined') {
      this.refs.Form.setAllValues(
        {
          ...user,
          address_state: user.address_state ? NormalizeBrazilianStates(user.address_state) : '',
          address_complement: user.address_complement
            ? user.address_complement.length <= 10
              ? user.address_complement
              : ''
            : '',
            address_reference: user.address_reference
            ? user.address_reference.length <= 40
              ? user.address_reference
              : ''
            : '',
        },
        true,
      )
      if (
        _.every([
          user.address_zipcode,
          user.address_city,
          user.address_neighborhood,
          user.address_state,
          user.address_street,
        ])
      ) {
        this.setState(
          {
            address_found: `${user.address_street}, ${user.address_zipcode} - ${user.address_neighborhood} - ${user.address_city}, ${user.address_state}`,
            address_incomplete: false,
          },
          () => {
            this.refs.Form.setAllValues(
              {
                ...user,
                address_state: user.address_state ? NormalizeBrazilianStates(user.address_state) : '',
                address_complement: user.address_complement
                  ? user.address_complement.length <= 10
                    ? user.address_complement
                    : ''
                  : '',
                  address_reference: user.address_reference
                  ? user.address_reference.length <= 40
                    ? user.address_reference
                    : ''
                  : '',
              },
              true,
            )
          },
        )
      }
    }
  }

  /**
   * Verify if the email is already taken
   **/
  _getUserByEmail(event) {
    const email = event.target.value
    return this.props.getUserByEmail({ email }).then(() => {
      return new Promise(resolve => {
        this.setState(
          {
            email,
          },
          () => setTimeout(() => resolve(email), 300),
        )
      })
    })
  }

  /**
   * Toggle CPF and CNPJ fields
   **/
  _toggleTypeField(name, setValue) {
    if (name === 'cnpj') this.setState({ ieModalOpen: true })
    this.setState({
      showingField: name,
    })
  }

  /**
   * All validations rules
   **/
  _validateForm(values) {
    const errorMsg = {
      empty: 'Campo obrigatório',
      confirmEmail: 'Os e-mails devem ser iguais',
      validZipcode: 'Cep Inválido',
      notfoundZipcode: 'Cep Inválido',
      validEmail: 'Email inválido',
      password: `A senha deve conter no mínimo 8 caracteres, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula, números de 0 a 9 e pelo menos um caractere especial (por exemplo '~ ! @ # $ % ^ & * ( ) _ + - = { } | \\ : " ; ' < > ? , . /)`,
      passwordLength: `A senha deve conter no mínimo 8 caracteres, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula, números de 0 a 9 e pelo menos um caractere especial (por exemplo '~ ! @ # $ % ^ & * ( ) _ + - = { } | \\ : " ; ' < > ? , . /)`,
      confirmPassword: 'As senhas devem ser iguais',
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
      ie: 'Inscrição Estadual inválida',
      addressNumberLength: 'O número deve conter no máximo 10 caracteres',
      addressComplementLength: 'O complemento deve conter no máximo 10 caracteres',
    }
    const {
      email,
      confirm_email,
      full_name,
      phone,
      cpf,
      cnpj,
      ie,
      address_zipcode,
      address_street,
      address_number,
      address_neighborhood,
      address_city,
      address_state,
      address_complement,
      address_reference,
      type,
      password,
      confirm_password,
    } = values

    let error = {
      full_name: !full_name ? errorMsg.empty : undefined,
      phone: !phone ? errorMsg.empty : undefined,
      confirm_email: !this.props.edit && confirm_email !== email ? errorMsg.confirmEmail : undefined,
      address_zipcode: !address_zipcode
        ? errorMsg.empty
        : !regexZipcodeFull.test(address_zipcode)
        ? errorMsg.validZipcode
        : undefined,
      address_street: !address_street ? errorMsg.empty : undefined,
      address_number: !address_number
        ? errorMsg.empty
        : address_number.toString().length > 10
        ? errorMsg.addressNumberLength
        : undefined,
      address_neighborhood: !address_neighborhood ? errorMsg.empty : undefined,
      address_city: !address_city ? errorMsg.empty : undefined,
      address_state: !address_state ? errorMsg.empty : undefined,
      address_complement:
        address_complement && address_complement.length > 10 ? errorMsg.addressComplementLength : undefined,
        address_reference:
        address_reference && address_reference.length > 40 ? errorMsg.addressReferenceLength : undefined,
      type: !type ? errorMsg.empty : undefined,
      password:
        !this.props.edit && !password
          ? errorMsg.empty
          : password !== undefined && !regexPassword.test(password)
          ? errorMsg.passwordLength
          : undefined,
      confirm_password:
        !this.props.edit && !confirm_password
          ? errorMsg.empty
          : confirm_password !== password
          ? errorMsg.confirmPassword
          : undefined,
    }

    if (this.state.showingField === 'cpf') {
      error.cpf = !CPF.isValid(cpf) ? 'CPF inválido' : !cpf ? errorMsg.empty : undefined
    }

    if (this.state.showingField === 'cnpj') {
      error.cnpj = !CNPJ.isValid(cnpj) ? 'CNPJ inválido' : !cnpj ? errorMsg.empty : undefined
      error.ie = !ie ? 'Inscrição Estadual obrigatória' : undefined
    }

    if ((this.state.userPage !== null && this.state.userPage.email !== email) || !this.props.edit) {
      error.email = this.props.user.error
    }

    if (email && !regexEmail.test(email)) {
      error.email = 'Email inválido'
    }

    if (!email) {
      error.email = errorMsg.empty
    }

    if (phone && phone.length <= 9) {
      error.phone = 'Telefone inválido'
    }

    return error
  }

  _handleEdit(values) {
    let user = values

    delete user.privacy_policy_accepted_at
    delete user.terms_of_use_accepted_at

    user.password_updated_at = moment().format('YYYY-MM-DD HH:mm:ss')
    user.schools = user.schools?.map(school => school.value)

    if (values.role_id !== 2) delete values.school_id

    if (this.props.edit) {
      if (user.password && !user.confirm_password) {
        delete user.password
      }
      user.id = this.props.params.id
      user.token = this.props.user.token
      this.props.requestUpdate(user)
      this.props.openModal(
        <ConfirmDialog
          text="Usuário salvo com sucesso"
          okLabel="Confirmar"
          ok={() => {
            this.props.closeModal()
            if (this.state.ie_free) {
              this.refs.Form.setValue('ie_free', 1)
            }
          }}
        />,
      )
    } else {
      delete user['confirm_email']
      delete user['confirm_password']
      delete user['terms']
      user['school_id'] =
        this.props.sessionReducer.user.roles[0].name === 'SM TI'
          ? user['school_id']
          : this.props.sessionReducer.user.school_id

      user.role_id = user.role_id || 2
      this.props.submitRegisterForm(user).then(response => {
        if (typeof response.insertId !== 'undefined') {
          this.props.openModal(
            <ConfirmDialog
              text="Usuário salvo com sucesso"
              okLabel="Confirmar"
              ok={() => {
                this.props.navigate('/admin/relatorios/usuarios')
                this.props.closeModal()
              }}
            />,
          )
        }
      })
    }
  }

  _handleDelete() {
    let user = {
      id: this.props.params.id,
      token: this.props.user.token,
    }
    let { openModal, closeModal } = this.props
    openModal(
      <ConfirmDialog
        text="Tem certeza que deseja inativar esse usuário?"
        ok={() => {
          this.props.requestDelete(user)
          this.props.navigate('/admin/relatorios/usuarios')
          closeModal()
        }}
        cancel={() => closeModal()}
      />,
    )
  }

  _handleEnable() {
    let user = {
      id: this.props.params.id,
      token: this.props.user.token,
    }
    let { openModal, closeModal } = this.props
    openModal(
      <ConfirmDialog
        text="Tem certeza que deseja ativar esse usuário?"
        ok={() => {
          this.props.requestEnable(user)
          this.props.navigate('/admin/relatorios/usuarios')
          closeModal()
        }}
        cancel={() => closeModal()}
      />,
    )
  }

  render() {
    let { sessionReducer } = this.props

    if (this.state.showForm) {
      return (
        <div className="admin-form">
          <Form
            ref="Form"
            validate={values => {
              const validation = this._validateForm(values)
              setTimeout(() => {
                if (!this.state.address_zipcode || !regexZipcodeFull.test(this.state.address_zipcode)) {
                  this.setState({
                    address_incomplete: true,
                  })
                }
              }, 300)

              return validation
            }}
            onSubmit={values => {
              this._handleEdit(values)
            }}
          >
            {({ submitForm, swapTo, setValue, getValue }) => {
              return (
                <form className="admin-form__form" onSubmit={submitForm}>
                  <div className="admin-form__header">
                    <h1 className="admin-form__title">Perfil</h1>
                    {this.props.edit && this.state.deleted ? (
                      <button
                        type="button"
                        onClick={() => this._handleEnable()}
                        className="button__small admin-form__header__button"
                      >
                        ativar usuário
                      </button>
                    ) : null}
                    {this.props.edit && !this.state.deleted ? (
                      <button
                        type="button"
                        onClick={() => this._handleDelete()}
                        className="button__small admin-form__header__button"
                      >
                        inativar usuário
                      </button>
                    ) : null}
                  </div>
                  <div className="field">
                    <span className="field__label admin-form__form__label">Email *</span>
                    <InputText
                      autoComplete="off"
                      handleBlur={event => {
                        this._getUserByEmail(event)
                      }}
                      className="field__text"
                      field="email"
                    />
                  </div>
                  {!this.props.edit && (
                    <div className="field">
                      <span className="field__label admin-form__form__label">Confirme seu e-mail</span>
                      <InputText blockPaste className="field__text" field="confirm_email" />
                    </div>
                  )}
                  <div className="field">
                    <span className="field__label admin-form__form__label">Senha *</span>
                    <InputPassword placeholder="" field="password" />
                  </div>
                  <div className="field">
                    <span className="field__label admin-form__form__label">Confirme sua senha *</span>
                    <InputPassword
                      onPaste={event => {
                        event.preventDefault()
                        return false
                      }}
                      placeholder=""
                      field="confirm_password"
                    />
                  </div>
                  <div className="field">
                    <span className="field__label admin-form__form__label">Nome Completo *</span>
                    <Text className="field__text" field="full_name" autoComplete="off" />
                  </div>
                  <div className="field">
                    <span className="field__label admin-form__form__label">Telefone *</span>
                    <InputText
                      field="phone"
                      mask="(99) 9999-9999?"
                      formatChars={{
                        9: '[0-9]',
                        '?': '[0-9]',
                      }}
                      from={/\D/g}
                    />
                  </div>

                  <label className="field">
                    <span className="field__label admin-form__form__label">CEP *</span>
                    <InputText
                      handleBlur={e => {
                        this._getAddress(e, setValue)
                      }}
                      mask="99999-999"
                      field="address_zipcode"
                      className="field__text"
                    />
                    <a
                      className="admin-form__cep-link"
                      href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm"
                      target="_blank"
                    >
                      Não sabe o CEP?
                    </a>
                    {this.state.invalid_address_zipcode ? (
                      <div class="FormError invalid-zip">Dados não encontrados para o cep informado</div>
                    ) : null}
                  </label>

                  <label className={`field ${!this.state.unique_zipcode ? 'disabled' : ''}`}>
                    <span className="field__label admin-form__form__label">Logradouro</span>
                    <Text
                      className="field__text"
                      field="address_street"
                      disabled={!this.state.unique_zipcode}
                      autoComplete="off"
                    />
                  </label>
                  <label className={`field ${!this.state.address_found ? 'disabled' : ''}`}>
                    <span className="field__label admin-form__form__label">Número</span>
                    <Text className="field__text" field="address_number" maxLength="10" autoComplete="off" />
                  </label>

                  <div className={`field ${!this.state.address_found ? 'disabled' : ''}`}>
                    <span className="field__label admin-form__form__label">Complemento</span>
                    <Text className="field__text" field="address_complement" maxLength="10" autoComplete="off" />
                  </div>
                  <div className={`field ${!this.state.address_found ? 'disabled' : ''}`}>
                    <span className="field__label admin-form__form__label">Ponto de referência</span>
                    <Text className="field__text" field="address_reference" maxLength="40" autoComplete="off" />
                  </div>
                  <label className={`field ${!this.state.unique_zipcode ? 'disabled' : ''}`}>
                    <span className="field__label admin-form__form__label">Bairro</span>
                    <Text
                      className="field__text"
                      field="address_neighborhood"
                      disabled={!this.state.unique_zipcode}
                      autoComplete="off"
                    />
                  </label>
                  <label className={`field ${this.state.disable_address_state ? 'disabled' : ''}`}>
                    <span className="field__label admin-form__form__label">Estado</span>
                    <SelectState
                      className="field__text"
                      field="address_state"
                      disabled={this.state.disable_address_state}
                      autoComplete="off"
                    />
                  </label>
                  <label className="field disabled">
                    <span className="field__label admin-form__form__label">Cidade</span>
                    <Text className="field__text" field="address_city" disabled autoComplete="off" />
                  </label>
                  <div className="field">
                    <span className="field__label admin-form__form__label">CPF/CNPJ *</span>
                    <RadioGroup
                      field="type"
                      errorProps={this.hideDefaultError}
                      errorBefore={false}
                      className="field__radio"
                    >
                      <label className="field__radio__option">
                        <Radio
                          value="cpf"
                          onChange={() => {
                            this._toggleTypeField('cpf')
                            setValue('cnpj', '', true)
                            setValue('ie', null, true)
                          }}
                        />
                        <span className="field__radio__label">Pessoa física</span>
                      </label>
                      <label className="field__radio__option">
                        <Radio
                          value="cnpj"
                          onChange={() => {
                            this._toggleTypeField('cnpj')
                            setValue('cpf', '', true)
                          }}
                        />
                        <span className="field__radio__label">Pessoa jurídica</span>
                      </label>
                    </RadioGroup>
                    {getValue('type') === 'cpf' ? (
                      <div className="field--left admin-form__field__cpf">
                        <InputText field="cpf" mask="999.999.999-99" from={/\D/g} />
                      </div>
                    ) : null}
                    {getValue('type') === 'cnpj' ? (
                      <div className="field--left admin-form__field__cnpj">
                        <InputText field="cnpj" mask="99.999.999/9999-99" from={/\D/g} />
                      </div>
                    ) : null}
                  </div>
                  {getValue('type') === 'cnpj' ? (
                    <React.Fragment>
                      {!this.state.ie_free ? (
                        <div className="field">
                          <span className="field__label admin-form__form__label">Inscrição Estadual *</span>
                          <InputText field="ie" mask="" from={/\D/g} />
                        </div>
                      ) : null}
                      <div className="admin-form__checkbox">
                        <div className="field">
                          <label>
                            <InputCheckbox field="ie_free" onChange={() => this._toggleIE()} />
                            <span className="admin-form__checkboxlabel">Isento</span>
                          </label>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                  {sessionReducer.user.permissions.includes('create_users') && (
                    <div className="field field__perfil">
                      <span className="field__label admin-form__form__label">Perfil</span>
                      <RadioGroup errorBefore={false} field="role_id" className="field__radio">
                        <label className="field__radio__option">
                          <Radio value={1} />
                          <span className="field__radio__label">Comprador</span>
                        </label>
                        <label className="field__radio__option">
                          <Radio value={2} />
                          <span className="field__radio__label">Escola</span>
                        </label>
                        <label className="field__radio__option">
                          <Radio value={3} />
                          <span className="field__radio__label">SM</span>
                        </label>
                        <label className="field__radio__option">
                          <Radio value={4} />
                          <span className="field__radio__label">Atendimento pré venda</span>
                        </label>
                        <label className="field__radio__option">
                          <Radio value={5} />
                          <span className="field__radio__label">Atendimento pós venda</span>
                        </label>
                        <label className="field__radio__option">
                          <Radio value={6} />
                          <span className="field__radio__label">Gerente Regional</span>
                        </label>
                        <label className="field__radio__option">
                          <Radio value={7} />
                          <span className="field__radio__label">Financeiro</span>
                        </label>
                      </RadioGroup>
                    </div>
                  )}
                  {sessionReducer.user.roles[0].name === 'SM TI' && getValue()['role_id'] === 2 && (
                    <>
                      <div className="field">
                        <span className="field__label admin-form__form__label">Escola *</span>
                        <div className="field__select">
                          <Select placeholder="Escola" field="school_id" options={this.state.schools} />
                        </div>
                      </div>
                      <div className="field field__school-search">
                        <span className="field__label admin-form__form__label">Escolas para visualização</span>
                        <SelectSchoolsUser
                          getValue={getValue}
                          setValue={setValue}
                          field="schools"
                          schools={this.state.schools}
                        />
                      </div>
                    </>
                  )}
                  {sessionReducer.user.permissions.includes('create_users') && (
                    <div className="admin-form__checkbox">
                      <span className="field__label admin-form__checkboxtitle">Permissões</span>
                      {userPermissions.map((permission, index) => {
                        return (
                          <div key={index} className="field">
                            <label>
                              <InputCheckbox field={permission.name} />
                              <span className="admin-form__checkboxlabel">{permission.label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  )}
                  <div className="field">
                    <hr className="admin-form__division" />
                  </div>
                  <div className="field__terms admin-form__terms">
                    <label>
                      <InputCheckbox
                        field="receive_offers"
                        checked={getValue('receive_offers') === undefined ? false : !getValue('receive_offers')}
                      />
                      <span className="field__checkbox__label register__terms__label">
                        Não desejo receber comunicações comerciais do Grupo SM
                      </span>
                    </label>
                    <br />
                    <br />
                    <PermissionsChecker permissions={['edit_users']}>
                      <button className="admin-form__button button__normal" type="submit">
                        Salvar
                      </button>
                    </PermissionsChecker>
                    <PermissionsChecker permissions={['edit_users']} negate>
                      {this.state.userPage && sessionReducer.user.id === this.state.userPage.id && (
                        <button className="admin-form__button button__normal" type="submit">
                          Salvar
                        </button>
                      )}
                    </PermissionsChecker>
                  </div>
                </form>
              )
            }}
          </Form>

          <Modal show={this.state.ieModalOpen}>
            <Modal.Body>
              <div className="modalHeader">
                <span className="icon-alert" />
                <h1>Aviso</h1>
              </div>

              <div className="modalContent">
                <p>
                  Caso você possua a <span className="yellow-text">Inscrição Estadual Ativa</span> e opte por não
                  preencher este campo, a sua nota fiscal <strong>NÃO</strong> será aprovada pelo <strong>SEFAZ</strong>
                </p>
              </div>

              <div className="modalFooter">
                <button
                  className="modal-close"
                  onClick={() => {
                    this.setState({
                      ieModalOpen: false,
                    })
                  }}
                >
                  Entendi
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state, props) => ({
  sessionReducer: state.sessionReducer,
  user: state.user,
  params: props.params,
})

const mapDispatchToProps = dispatch => ({
  getUserByEmail: email => dispatch(getUserByEmail(email)),
  requestUpdate: user => dispatch(requestUpdate(user)),
  requestDelete: user => dispatch(requestDelete(user)),
  requestEnable: user => dispatch(requestEnable(user)),
  getUserData: async user => getUserData(user),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  submitRegisterForm: user => dispatch(requestRegister(user, true)),
  openModal: component => dispatch(openModal(component)),
  closeModal: () => dispatch(closeModal()),
  getSchools: () => dispatch(getSchools()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUser))

