import React, { Component } from 'react'

import CustomDateInput from './CustomDateInput'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import moment from 'moment'

export default class MultipleDateInput extends Component {
  state = {
    startDate: moment(),
    endDate: moment(),
    cutDatesQuantity: 0,
    cutDates: [],
    rawStart: null,
    rawEnd: null,
  }

  handleChangeStart(date) {
    this.setState(
      {
        startDate: date,
      },
      () =>
        this.props.onChange({
          date_start: this.state.startDate.format('YYYY-MM-DD'),
        }),
    )
  }

  addCutDate(event) {
    let cutDates = this.state.cutDates
    cutDates.push({
      date: moment(),
      id: null,
      scheduled_date: null
    })

    let cutDatesQuantity = cutDates.length

    let updateCutDates = []

    cutDates.forEach(function (cutDate, index) {
      updateCutDates[index] = {
        cut_date: moment(cutDate.date).format('YYYY-MM-DD'),
        id: cutDate.id,
        scheduled_date: cutDate.scheduled_date
      }
    })

    this.setState(
      {
        cutDates: cutDates,
        cutDatesQuantity: cutDatesQuantity,
      },
      () => this.props.onChange({ cut_dates: updateCutDates }),
    )
  }

  removeCutDate(event, index) {
    let result = []
    let cutDates = this.state.cutDates
    cutDates.forEach(function (date, i) {
      if (i !== index) {
        result.push(date)
      }
    })

    let updateCutDates = []

    result.forEach(function (cutDate, index) {
      updateCutDates[index] = {
        cut_date: moment(cutDate.date).format('YYYY-MM-DD'),
        id: cutDate.id,
        scheduled_date: cutDate.scheduled_date
      }
    })

    this.setState(
      {
        cutDates: result,
        cutDatesQuantity: result.length,
      },
      () => this.props.onChange({ cut_dates: updateCutDates }),
    )
  }

  handleChangeCutDate(date, i) {
    let cutDates = this.state.cutDates

    let updateCutDates = []

    cutDates[i] = {
      date: moment(date),
      id: this.state.cutDates[i] ? this.state.cutDates[i].id : null,
      scheduled_date: this.state.cutDates[i].scheduled_date
    }

    cutDates.forEach(function (cutDate, index) {
      updateCutDates[index] = {
        cut_date: moment(cutDate.date).format('YYYY-MM-DD'),
        id: cutDate.id,
        scheduled_date: cutDate.scheduled_date
      }
    })

    this.setState(
      {
        cutDates: cutDates,
      },
      () => this.props.onChange({ cut_dates: updateCutDates }),
    )
  }

  handleChangeEnd(date) {
    this.setState(
      {
        endDate: date,
      },
      () =>
        this.props.onChange({
          date_end: this.state.endDate.format('YYYY-MM-DD'),
        }),
    )
  }

  UNSAFE_componentWillMount() {
    moment.locale('pt-br')
    this.setState({
      rawStart: this.props.startDate,
      rawEnd: this.props.endDate,
      startDate: this.props.startDate ? moment(this.props.startDate) : null,
      endDate: this.props.endDate ? moment(this.props.endDate) : null,
      cutDates: this.props.cutDates ? this.props.cutDates : null,
      cutDatesQuantity: this.props.cutDates ? this.props.cutDates.length : 0,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.rawStart !== nextProps.startDate) {
      let start = nextProps.startDate === null ? nextProps.startDate : moment(nextProps.startDate)
      this.setState({ startDate: start, rawStart: nextProps.startDate })
    }

    if (this.state.rawEnd !== nextProps.endDate) {
      let end = nextProps.endDate === null ? nextProps.endDate : moment(nextProps.endDate)
      this.setState({ endDate: end, rawEnd: nextProps.endDate })
    }

    let momentCutDates = []
    if (nextProps.cutDates) {
      nextProps.cutDates.forEach(function (date) {
        momentCutDates.push({
          date: moment(date.cut_date),
          id: date.id,
          scheduled_date: date.scheduled_date
        })
      })
    }
    this.setState({
      cutDates: momentCutDates,
      cutDatesQuantity: nextProps.cutDates.length,
    })
  }

  render() {
    let self = this

    let intervalInputs = []

    if (self.state.cutDates) {
      self.state.cutDates.forEach(function (cutDate, i) {
        intervalInputs.push(
          <div className="multipledate__field" key={i}>
            {self.props.blockEdit ? (
              <CustomDateInput
                label={`Data de corte ${i + 1}`}
                value={self.state.cutDates[i] ? moment(self.state.cutDates[i].date).format('DD/MM/YYYY') : ''}
              />
            ) : (
              <React.Fragment>
                <DatePicker
                  customInput={<CustomDateInput label={`Data de corte ${i + 1}`} />}
                  dateFormat="DD/MM/YYYY"
                  selected={self.state.cutDates[i] ? self.state.cutDates[i].date : moment()}
                  testProp={`interval_${i}`}
                  onChange={date => self.handleChangeCutDate(date, i)}
                />
                <button className="deleteCutDateButton" onClickCapture={e => self.removeCutDate(e, i)} />
              </React.Fragment>
            )}
          </div>,
        )
      })
    }
    return (
      <div className="multipledate">
        <div className="multipledate__field">
          {this.props.blockEdit ? (
            <CustomDateInput
              label="Início"
              value={this.state.startDate ? moment(this.state.startDate).format('DD/MM/YYYY') : ''}
            />
          ) : (
            <DatePicker
              customInput={<CustomDateInput label="Início" />}
              dateFormat="DD/MM/YYYY"
              selected={this.state.startDate}
              selectsStart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={date => this.handleChangeStart(date)}
              testProp={'from'}
            />
          )}
        </div>

        {intervalInputs}

        <div className="multipledate__field">
          {this.props.blockEdit ? (
            <CustomDateInput
              label="Fim"
              value={this.state.endDate ? moment(this.state.endDate).format('DD/MM/YYYY') : ''}
            />
          ) : (
            <DatePicker
              customInput={<CustomDateInput label="Fim" />}
              dateFormat="DD/MM/YYYY"
              selected={this.state.endDate}
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={date => this.handleChangeEnd(date)}
              testProp={'to'}
            />
          )}
        </div>
        {!this.props.blockEdit && (
          <button className="addCutDateButton" onClickCapture={e => this.addCutDate(e)}>
            + Adicionar data de corte
          </button>
        )}
        {this.props.children}
      </div>
    )
  }
}

MultipleDateInput.propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  cutDatesQuantity: PropTypes.number,
  cutDates: PropTypes.array,
}
